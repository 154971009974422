import React, { useEffect, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import EmailGroupData from "./EmailGroupData"
import LoadingOverlay from "react-loading-overlay"
import Success from "pages/NotificationModal/Success"
import Cookies from "js-cookie"

const EmailRuleEditForm = ({
  emailGroup,
  stagedData,
  cancelEdit,
  mLocId,
  reloadStagedData,
}) => {
  const [emailGroupRuleBased, setEmailGroupRuleBased] = useState(
    stagedData.hasOwnProperty("email_grp_rule_based")
      ? stagedData.email_grp_rule_based
      : false
  )
  const [to, setTo] = useState(stagedData.to)
  const [cc, setCc] = useState(stagedData?.cc)
  const [emailRule, setEmailRule] = useState(stagedData?.email_rule)
  const [updatedEmailGroup, setUpdatedEmailGroup] = useState(emailGroup)
  const [loading, setLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    console.log("staged data", stagedData)
  }, [])

  const updateEmailGroup = emailGroup => {
    setUpdatedEmailGroup(emailGroup)
  }

  const handleEmailTypeChange = event => {
    event.preventDefault()
    setEmailGroupRuleBased(event.target.value == "true" ? true : false)
  }

  const save = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")

      var data = {
        ...stagedData,
      }

      if (emailGroupRuleBased) {
        const result = updatedEmailGroup.reduce(
          (acc, { groupName, ...rest }) => {
            acc[groupName] = rest
            return acc
          },
          {}
        )

        data = {
          ...data,
          email_grp_rule_based: emailGroupRuleBased,
          email_rule: emailRule,
          email_groups: result,
        }
      } else {
        data = {
          ...data,
          email_grp_rule_based: false,
          email_rule: null,
          to: to,
          cc: cc,
        }
      }

      console.log("data for saving...", data)

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }

      const response = await fetch(
        api_url + "email/templatemapping/" + mLocId,
        config
      )
      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
        console.log("update response", jsonData)
        setLoading(false)
        setShowSuccessModal(true)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  //group_name: Yup.string().when('isDefault', {is: true, then: Yup.string().required('Please enter name of the group'), otherwise: Yup.string()}),

  const handleEmailRuleChange = event => {
    event.preventDefault()
    setEmailRule(event.target.value)
  }

  const handleToListChange = event => {
    setTo(event.target.value)
  }

  const handleCcListChange = event => {
    setCc(event.target.value)
  }

  const closeSuccess = () => {
    setShowSuccessModal(false)
    reloadStagedData()
  }

  return (
    <>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          closeSuccess()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !"
          close={closeSuccess}
        />
      </Modal>
      <LoadingOverlay active={loading} spinner>
        <Form
          onSubmit={e => {
            e.preventDefault()
            save()
            return false
          }}
        >
          <Row className="mb-2 font-weight-100">
            <Col sm="2" className={"text-muted"}>
              <span>Email Type:</span>
            </Col>
            <Col sm="3">
              <select
                className="form-control"
                name="emailType"
                onChange={handleEmailTypeChange}
                value={emailGroupRuleBased}
              >
                <option value={true}>Rule Based</option>
                <option value={false}>Static</option>
              </select>
            </Col>
          </Row>

          {!emailGroupRuleBased && (
            <>
              <Row className="mb-2 font-weight-100">
                <Col sm="2" className={"text-muted"}>
                  <span>To List:</span>
                </Col>
                <Col sm="6">
                  <textarea
                    value={to}
                    className="form-control"
                    name="toList"
                    onChange={event => handleToListChange(event)}
                    onBlur={event => handleToListChange(event)}
                  />
                </Col>
              </Row>
              <Row className="mb-2 font-weight-100">
                <Col sm="2" className={"text-muted"}>
                  <span>Cc List:</span>
                </Col>
                <Col sm={6}>
                  <textarea
                    value={cc}
                    className="form-control"
                    name="ccList"
                    onChange={event => handleCcListChange(event)}
                    onBlur={event => handleCcListChange(event)}
                  />
                </Col>
              </Row>
            </>
          )}

          {emailGroupRuleBased && (
            <>
              <Row className="mb-2 font-weight-100">
                <Col sm="2" className={"text-muted"}>
                  <span>Email Rule:</span>
                </Col>
                <Col sm="6">
                  <Input
                    value={emailRule}
                    className="form-control"
                    name="emailRule"
                    onChange={handleEmailRuleChange}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2 font-weight-100">
                <Col sm="2" className={"text-muted"}>
                  <span>Email Group:</span>
                </Col>
                <Col>
                  <EmailGroupData
                    isEdit={true}
                    groups={updatedEmailGroup}
                    updateEmailGroup={updateEmailGroup}
                    mLocId={mLocId}
                  />
                </Col>
              </Row>
            </>
          )}

          <Row>
            <div className="modal-footer">
              <Button type="submit" className="btn btn-sm me-2" color="primary">
                Save
              </Button>
              <Button
                type="button"
                className="btn btn-sm"
                color="secondary"
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </Form>
      </LoadingOverlay>
    </>
  )
}

export default EmailRuleEditForm
