import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  Row,
  Table,
} from "reactstrap"

import { useFormik } from "formik"
import * as Yup from "yup"
import Success from "pages/NotificationModal/Success"
import LoadingOverlay from "react-loading-overlay"
import ValidationData from "./ValidationData"
import VarData from "./VarData"
import Cookies from "js-cookie"

const var_types = [
  { label: "PATH", value: "PATH" },
  { label: "FMT", value: "FMT" },
  { label: "EXPR", value: "EXPR" },
  { label: "LIT", value: "LIT" },
]

const ConfigEditForm = props => {
  const api_url = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [vars, setVars] = useState(props.data?.vars || [])
  const [formdata, setFormdata] = useState(props.data?.vars || [])
  const [selectedTemplate, setSelectedTemplate] = useState(
    props.data.htmlTemplate || ""
  )
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [validations, setValidations] = useState(props.data?.validations || [])

  useEffect(() => {
    //setFormDataForVars(selectedTemplate)
    // setVars(jsonData.vars)
    // setFormdata(jsonData.vars)
    // loadAllTemplate()
    console.log(formdata)
  }, [])

  const loadAllTemplate = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "email/templates?cfgType=published",
        { credentials: "include" }
      )
      if (!response.ok) {
        throw new Error("Request failed")
      }
      const jsonData = await response.json()
      if (jsonData) {
        setTemplates(jsonData)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const save = async () => {
    try {
      setLoading(true)

      const sig = validation.values.sig_identifier_regex
        ? validation.values.sig_identifier_regex.trim()
        : null
      const conf = validation.values.confirmation_msgs
        ? validation.values.confirmation_msgs.trim()
        : null
      const toAddresses = validation.values.tos
        ? validation.values.tos.trim()
        : null
      const ccAddresses = validation.values.ccs
        ? validation.values.ccs.trim()
        : null
      const fromAddress = validation.values.from
        ? validation.values.from.trim()
        : null
      var data = {
        // 'htmlTemplate': selectedTemplate,
        vars: formdata,
        confirmation_msgs: conf && conf.length > 0 ? conf.split("\n") : [],
        sig_identifier_regex: sig && sig.length > 0 ? sig.split("\n") : [],
        validations: validations,
        to: toAddresses,
        cc: ccAddresses,
        from: fromAddress,
      }
      const token = Cookies.get("idToken")

      const config = {
        method: props.locationId ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
      const response = await fetch(
        api_url + "opendock/configmapping/" + props.m_loc_id,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
      setShowSuccessModal(true)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const setFormDataForVars = async value => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "email/templates/" + value, {
        credentials: "include",
      })
      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      if (jsonData) {
        setVars(jsonData.vars)
        setFormdata(jsonData.vars)
        setValidations(jsonData.validations)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const handleTemplateChange = async value => {
    //setFormDataForVars(value)
    setSelectedTemplate(value)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      htmlTemplate: selectedTemplate,
      confirmation_msgs:
        props.data.confirmation_msgs && props.data.confirmation_msgs?.length > 0
          ? props.data.confirmation_msgs.join("\n")
          : null,
      sig_identifier_regex:
        props.data.sig_identifier_regex?.length > 0
          ? props.data.sig_identifier_regex.join("\n")
          : null,
      tos: props.data.to ? props.data.to : null,
      ccs: props.data.cc ? props.data.cc : null,
      from: props.data.from ? props.data.from : null,
    },
    validationSchema: Yup.object({
      htmlTemplate: Yup.string().required("Please select html template"),
      confirmation_msgs: Yup.string().required(
        "Please enter the confirmation message"
      ),
      sig_identifier_regex: Yup.string().required(
        "Please enter the sign indentifier"
      ),
      vars: Yup.string().required("Pls enter the value"),
    }),

    onSubmit: values => {
      save(values)
    },
  })

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  return (
    <>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          togSuccessModal()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !!"
          close={props.cancel}
        />
      </Modal>
      <Form
        onSubmit={e => {
          e.preventDefault()
          save()
          return false
        }}
      >
        <LoadingOverlay active={loading} spinner>
          <CardBody>
            <Row>
              <h5 className="text-primary">Edit Email Configuration</h5>
            </Row>
            <Row>
              <Card outline color="primary" className="border mt-3 mb-3">
                <CardBody className="p-2 cardBody">
                  <Row className="mb-3">
                    <VarData
                      vars={formdata}
                      isEdit={true}
                      isNew={false}
                      setVars={setFormdata}
                      key={formdata}
                    />
                  </Row>
                  <div className="border-bottom mb-3"></div>

                  <Row className="mt-2 mb-2">
                    <ValidationData
                      validations={validations}
                      isEdit={true}
                      setValidations={setValidations}
                      key={validations}
                    />
                  </Row>
                  <div className="border-bottom mb-3"></div>
                </CardBody>
              </Card>
            </Row>
          </CardBody>
          <div className="modal-footer d-flex justify-content-end gap-3">
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={props.cancel}>
              Cancel
            </Button>
          </div>
        </LoadingOverlay>
      </Form>
    </>
  )
}

export default ConfigEditForm
