import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Cookies from "js-cookie"

import classes from "./TemplateDetails.scss"
import classnames from "classnames"
import Preview from "./Preview"
import { useNavigate, useParams } from "react-router-dom"
import TemplateDetails from "./TemplateDetails"
import { template } from "lodash"
import EditForm from "./EditForm"
import PublishTempalteBox from "./PublishTemplateBox"
import TemplateDeleteBox from "./TemplateDeleteBox"
import Success from "pages/NotificationModal/Success"
import TemplateMaps from "./TemplateMaps"

const TemplateData = props => {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [singlebtn, setSinglebtn] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const { name } = useParams("name")
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [showPublishSuccess, setShowPublishSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setcustomActiveTab("1")
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url

      url = url + "email/templates/" + name

      const response = await fetch(url, { credentials: "include" })

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        throw new Error("Request failed")
      }

      const jsonData = await response.json()

      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const edit = () => {
    setIsEdit(true)
  }

  const confirmPublish = () => {
    setShowPublishConfirm(true)
  }

  const deleteTemplate = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "email/templates/" + name

      const config = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {},
        credentials: "include",
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setLoading(false)
        throw new Error(res.message)
      }
      const res = await response.json()
      console.log("hello success" + res.message)
      togDelete()
      setShowDeleteSuccess(true)
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
    }
  }

  const cancel = () => {
    setIsEdit(false)
  }

  function togPublish(status) {
    setShowPublishConfirm(!showPublishConfirm)
  }

  function togDelete() {
    setShowDeleteConfirm(!showDeleteConfirm)
  }

  const publish = async () => {
    setLoading(true)
    try {
      var url = api_url

      url = url + "email/templates/" + name + "/publish"

      const token = Cookies.get("idToken")

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {},
        credentials: "include",
      }
      const response = await fetch(
        api_url + "email/templates/" + name + "/publish",
        config
      )

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        throw new Error("Request failed")
      }

      setShowPublishConfirm(false)
      setShowPublishSuccess(true)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const goBack = () => {
    navigate("/email-templates")
  }

  const openDeleteModal = () => {
    setShowDeleteConfirm(true)
  }

  const togPubSuccess = () => {
    setShowPublishSuccess(!showPublishSuccess)
  }

  const togDeleteSuccess = () => {
    setShowDeleteSuccess(!showDeleteSuccess)
    navigate("/email-templates")
  }

  return (
    <QdContainer>
      <Modal
        isOpen={showPublishConfirm}
        toggle={() => {
          togPublish()
        }}
        centered
        size="md"
      >
        <PublishTempalteBox
          setShowPublishConfirm={setShowPublishConfirm}
          loading={loading}
          publish={publish}
          setLoading={setLoading}
          template={data}
        />
      </Modal>
      <Modal
        isOpen={showPublishSuccess}
        toggle={() => {
          togPubSuccess()
        }}
        centered
        size="sm"
      >
        <Success
          message="Successfully published the template. "
          close={goBack}
        />
      </Modal>
      <Modal
        isOpen={showDeleteSuccess}
        toggle={() => {
          togPubSuccess()
        }}
        centered
        size="sm"
      >
        <Success
          message="Successfully deleted the template. "
          close={togDeleteSuccess}
        />
      </Modal>
      <Modal
        isOpen={showDeleteConfirm}
        toggle={() => {
          togDelete()
        }}
        centered
        size="md"
      >
        <TemplateDeleteBox
          setShowDeleteConfirm={setShowDeleteConfirm}
          loading={loading}
          deleteTemplate={deleteTemplate}
          setLoading={setLoading}
          template={data}
        />
      </Modal>
      <Card>
        <CardBody>
          {loading && <div> loading...</div>}
          {!loading && (
            <Row>
              <Col>
                <span className="text-primary text-size-16 mr-1">{name}</span>
                {data?.cfgType == "published" && (
                  <Badge color="success" className="me-3">
                    Published
                  </Badge>
                )}
                {data?.cfgType == "staged" && (
                  <Badge color="secondary" className="me-3">
                    Staged
                  </Badge>
                )}
              </Col>
              <Col>
                {data?.cfgType == "staged" && !isEdit && (
                  <div className="float-end ms-1">
                    <Dropdown
                      className="mb-2 popup"
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                    >
                      <DropdownToggle
                        className="font-size-16 text-muted"
                        tag="a"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={edit}>Edit</DropdownItem>
                        <DropdownItem onClick={openDeleteModal}>
                          Delete
                        </DropdownItem>
                        <DropdownItem onClick={confirmPublish}>
                          Publish
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                <div className="float-end me-1">
                  <Button
                    color="primary"
                    className="btn btn-sm btn-primary"
                    onClick={goBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      {!isEdit && (
        <Card>
          <CardBody>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Template Details</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Preview</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3")
                  }}
                  disabled={data?.cfgType !== "published"}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Maps</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <TemplateDetails name={name} />
              </TabPane>
              <TabPane tabId="2">
                <Preview template_name={name} />
              </TabPane>
              <TabPane tabId="3">
                <TemplateMaps templateName={name} refresh={fetchData} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      )}
      {isEdit && <EditForm data={data} isClone={false} cancel={cancel} />}
    </QdContainer>
  )
}

export default TemplateData
