import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledAlert,
} from "reactstrap"
import Cookies from "js-cookie"

import { useFormik } from "formik"
import LoadingOverlay from "react-loading-overlay"
import * as Yup from "yup"
import Success from "pages/NotificationModal/Success"

const var_types = [
  { label: "PATH", value: "PATH" },
  { label: "FMT", value: "FMT" },
  { label: "EXPR", value: "EXPR" },
  { label: "LIT", value: "LIT" },
]

const TemplateConfigurationForm = props => {
  const api_url = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [vars, setVars] = useState([])
  const [formdata, setFormdata] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showTemplateConfiguration, setShowTemplateConfiguration] =
    useState(false)

  useEffect(() => {
    loadAllTemplate()
  }, [])

  const loadAllTemplate = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "email/templates", {
        credentials: "include",
      })
      if (!response.ok) {
        throw new Error("Request failed")
      }
      const jsonData = await response.json()
      if (jsonData) {
        setTemplates(jsonData)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const handleVarChange = (event, index) => {
    const { name, value } = event.target
    const updatedFormData = [...formdata]
    updatedFormData[index] = {
      ...updatedFormData[index],
      value: value,
      required: value.trim() === "",
    }
    setFormdata(updatedFormData)
  }

  const handleNotesChange = (event, index) => {
    const { name, value } = event.target
    const updatedFormData = [...formdata]
    updatedFormData[index] = { ...updatedFormData[index], notes: value }
    setFormdata(updatedFormData)
  }

  const showConfiguration = () => {
    setShowTemplateConfiguration(true)
  }

  const save = async () => {
    try {
      setLoading(true)
      var data = {
        htmlTemplate: selectedTemplate,
        vars: formdata,
        confirmation_msgs: validation.values.confirmationMsgs.split("\n"),
        sig_identifier_regex: validation.values.sigIdentifierRegex.split("\n"),
      }
      const token = Cookies.get("idToken")

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
      const response = await fetch(
        api_url + "email/templatemapping/" + props.mLocId,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
      setShowSuccessModal(true)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const setFormDataForVars = async value => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "email/templates/" + value, {
        credentials: "include",
      })
      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      if (jsonData) {
        setVars(jsonData.vars)
        setFormdata(jsonData.vars)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const handleTemplateChange = async value => {
    setFormDataForVars(value)
    setSelectedTemplate(value)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      htmlTemplate: selectedTemplate,
      confirmationMsgs: "",
      sigIdentifierRegex: "",
    },
    validationSchema: Yup.object({
      htmlTemplate: Yup.string().required("Please select html template"),
      confirmationMsgs: Yup.string().required(
        "Please enter the confirmation message"
      ),
      sigIdentifierRegex: Yup.string().required(
        "Please enter the sign indentifier"
      ),
      vars: Yup.string().required("Pls enter the value"),
    }),

    onSubmit: values => {
      save(values)
    },
  })

  const handleTypeChange = (event, index) => {
    const { name, value } = event.target
    const updatedFormData = [...formdata]
    updatedFormData[index] = { ...updatedFormData[index], type: value }
    setFormdata(updatedFormData)
  }

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  return (
    <div>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          togSuccessModal()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !!"
          close={props.cancel}
        />
      </Modal>
      <Form
        onSubmit={e => {
          e.preventDefault()
          save()
          return false
        }}
      >
        <LoadingOverlay active={loading} spinner>
          <CardBody>
            <Row>
              <Card>
                {templates && (
                  <CardBody className="p-2 cardBody">
                    <Row className="mb-2 font-weight-100">
                      <Col sm="2" className={"text-muted"}>
                        <span>Email Tempalte:</span>
                      </Col>
                      <Col sm="8">
                        <select
                          className="form-control"
                          name="htmlTemplate"
                          onChange={e => {
                            e.preventDefault()
                            handleTemplateChange(e.target.value)
                          }}
                          onBlur={validation.handleBlur}
                          value={selectedTemplate}
                        >
                          <option value="">Select Template</option>
                          {templates.map((item, index) => (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {validation.touched.htmlTemplate &&
                        validation.errors.htmlTemplate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.htmlTemplate}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row>
                      <Col sm="2" className={"text-muted"}>
                        <span>Variables:</span>
                      </Col>
                      <Col md="10">
                        <div className="table-responsive">
                          <Table className="project-list-table table-nowrap align-middle table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Value</th>
                                <th scope="col">Notes</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formdata?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <h5 className="text-truncate font-size-14 text-primary">
                                      {item.name}
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {item.desc}
                                    </p>
                                  </td>
                                  <td>
                                    <select
                                      className="form-control"
                                      name="htmlTemplate"
                                      onChange={e => {
                                        e.preventDefault()
                                        handleTypeChange(e, index)
                                      }}
                                      value={formdata[index].type}
                                    >
                                      {var_types.map((item, index) => (
                                        <option value={item.value} key={index}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      name={formdata[index].name}
                                      className="form-control"
                                      required
                                      value={formdata[index].value}
                                      onChange={e => {
                                        e.preventDefault()
                                        handleVarChange(e, index)
                                      }}
                                    />
                                    {formdata[index]?.required && (
                                      <div style={{ color: "red" }}>
                                        This field is required
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      name={formdata[index].notes}
                                      className="form-control"
                                      value={formdata[index].notes}
                                      onChange={e => {
                                        e.preventDefault()
                                        handleNotesChange(e, index)
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row>
                      <Col sm="2" className={"text-muted"}>
                        <span>Confirmation Message:</span>
                      </Col>
                      <Col md="8">
                        <textarea
                          value={validation.values.confirmationMsgs}
                          className="form-control"
                          name="confirmationMsgs"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.confirmationMsgs &&
                        validation.errors.confirmationMsgs ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmationMsgs}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row className="mt-1">
                      <Col sm="2" className={"text-muted"}>
                        <span>Signature:</span>
                      </Col>
                      <Col md="8">
                        <textarea
                          value={validation.values.sigIdentifierRegex}
                          className="form-control"
                          name="sigIdentifierRegex"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.sigIdentifierRegex &&
                        validation.errors.sigIdentifierRegex ? (
                          <FormFeedback type="invalid">
                            {validation.errors.sigIdentifierRegex}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm="2"></Col>
                      <Col className="d-flex gap-3">
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          onClick={props.cancel}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Row>
          </CardBody>
        </LoadingOverlay>
      </Form>
    </div>
  )
}

export default TemplateConfigurationForm
