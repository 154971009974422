import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ConfigData from './ConfigData'
import QdContainer from 'components/Common/QdContainer'

function OpenDockLocConfig() {
    const [mLocId, setMLocId] = useState()
    const [qdLocationId, setQdLocationId] = useState()
    const [locationName, setLocationName] = useState()
    const [loading, setLoading] = useState(false)
    const location = useLocation()

    useEffect(() => {
        initializeData()
    }, [])

    const initializeData = async () => {
        setLoading(true)
        const { state } = location || {}
        console.log(state)
        setMLocId(state?.m_loc_id)
        setQdLocationId(state?.locationId)
        setLocationName(state?.locName)
        setLoading(false)
    }


    return (
        <QdContainer>
            {loading &&<div>Loading....</div>}
            {!loading&& mLocId && locationName &&<ConfigData m_loc_id={mLocId} qdLocationId={qdLocationId} locationName={locationName} />}
        </QdContainer>
    )
}

export default OpenDockLocConfig

