import Cookies from "js-cookie";
import React from "react"
import { Navigate } from "react-router-dom"





const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const Authmiddleware = props => {
  const email = Cookies.get('email')
 
  if (!email) {
    localStorage.setItem('redirectUrl', (window.location.pathname + window.location.search).substring(1));
    return (
      <Navigate to={{ pathname: "/"}} />
    )
  }
 
  localStorage.setItem('redirectUrl', (window.location.pathname + window.location.search).substring(1));
  return (
    <React.Fragment>
     {props.children}
    </React.Fragment>
  )

  
}

export default Authmiddleware
