import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardSubtitle, Col, Row, UncontrolledAlert } from 'reactstrap'
import { get } from 'utils/apiClient'
import NewConfig from './NewConfig'
import BaseConfig from './BaseConfig'
import { useNavigate } from 'react-router-dom'


function ConfigData({ m_loc_id, qdLocationId, locationName }) {
    const [loading, setLoading] = useState(false)
    const [configData, setConfigData] = useState(null)
    const [needBaseReview, setNeedBaseReview] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        console.log(m_loc_id)
        console.log(locationName)
        fetchConfig()
    }, [])

    const fetchConfig = async () => {
        setLoading(true)
        try {
            const response = await get('location-management/opendock/' + m_loc_id)
            console.log("opendock rest call -->" + response.var_map[0].name)
            setConfigData(response)
            if ("review_base" in response) {
                setNeedBaseReview(response.review_base)
            }
            setLoading(false)

        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }


    const gotoLocationManagement = () => {
        navigate('/location-management')
    }

    return (
        <React.Fragment>
            <Card className='p-3'>
                <Row>
                    <Col>
                        <span className='text-primary text-size-16 m-2'>Opendock Location Configuration</span>
                    </Col>
                </Row>
                <CardSubtitle className='m-2'>{qdLocationId} - {locationName}</CardSubtitle>
            </Card>
            {loading && <div>Loading...</div>}
            {!loading && m_loc_id && locationName && <Card className='p-3'>
                <CardBody>
                    {needBaseReview && configData && (
                        <>
                            <UncontrolledAlert color="warning" role="alert">
                                Base configuration is missing for this location, Please save the below base configuration to proceed further.
                            </UncontrolledAlert>

                            <BaseConfig configData={configData} cancel={gotoLocationManagement} m_loc_id={m_loc_id} fetchConfig={fetchConfig}/>
                        </>
                    )}
                    {!needBaseReview && configData && (
                        <NewConfig m_loc_id={m_loc_id} configData={configData} cancel={gotoLocationManagement}/>
                    )}
                </CardBody>

            </Card>}
        </React.Fragment>
    )
}

export default ConfigData