import Confirmation from "pages/NotificationModal/Confirmation"
import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { Button, Card, CardBody, Col, Collapse, Modal, Row } from "reactstrap"
import { post } from "utils/apiClient"
import JsonEditor from "./JsonEditor"

const Preview = props => {
  const [sampleJsonEvent, setSampleJsonEvent] = useState()
  const [loading, setLoading] = useState(false)
  const [subHtml, setSubHtml] = useState()
  const [bodyHtml, setBodyHtml] = useState()
  const [showConfirm, setShowConfirm] = useState(false)
  const [validationMessages, setValidationMessages] = useState()
  const [processedVars, setProcessedVars] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const api_url = process.env.REACT_APP_API_URL

  const handleInputChange = () => {
    const { value } = event.target
    setSampleJsonEvent(value)
  }

  useEffect(() => {
    debugger
    fetch("/opendock_sample_event.json")
      .then(response => {
        if (response.ok) {
          return response.text()
        }
      })
      .then(text => {
        console.log(text)
        setSampleJsonEvent(JSON.parse(text))
      }

      )
      .catch(error => console.log(error))
  }, [])

  const validate = () => {
    try {
      const parsedData = JSON.parse(sampleJsonEvent)
      console.log(parsedData) // Do something with parsed data
    } catch (error) {
      console.error("Error parsing JSON:", error)
    }
  }

  const generatePreview = async () => {
    //validate()

    try {
      setLoading(true)
      var obj = {
        'config': props.configData.config,
        'var_maps': props.varMaps,
        'm_loc_id': props.m_loc_id,
        'is_tenant_specs': props.is_tenant_specs,
        'preview_request_json': sampleJsonEvent

      }


      const jsonresp = await post('location-management/opendock/preview', obj)
      if (jsonresp) {
        console.log(jsonresp)
        if (jsonresp.content?.body) {
          setBodyHtml(atob(jsonresp.content.body))
        }
        if (jsonresp.content?.subject) {
          setSubHtml(atob(jsonresp.content.subject))
        }

        if (jsonresp.validation_messages) {
          setValidationMessages(jsonresp.validation_messages)
        }

        if (jsonresp.var_map) {
          console.log(jsonresp.var_map)
          setProcessedVars(jsonresp.var_map)
        }
      }
      props.setIsPreviewed(true)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const confirmPublish = () => {
    setShowConfirm(true)
  }

  const toggleConfirmModal = () => {
    setShowConfirm(!showConfirm)
  }

  const publish = async () => {
    props.publish()
  }

  const closeConfirm = () => {
    setShowConfirm(false)
  }

  const updateSampleEventJson = data => {
    setSampleJsonEvent(data)
  }

  return (
    <>
      <Modal
        isOpen={showConfirm}
        toggle={() => {
          toggleConfirmModal()
        }}
        centered
        size="md"
      >
        <Confirmation
          message={"Are you sure you want to publish this template ?"}
          confirm={publish}
          cancel={closeConfirm}
        />
      </Modal>
      <Row>
        <Col className="p-0">
          <Card outline color="primary" className="border mt-3 mb-3">
            <CardBody>
              <span className="text-primary pb-4">Sample Json </span>

              <JsonEditor
                setSampleJsonEvent={updateSampleEventJson}
                data={sampleJsonEvent}
                setIsEdit={setIsEdit}
              />
              <br />
              {!isEdit && (
                <Button
                  type="button"
                  className="btn btn-soft-primary btn-sm waves-effect waves-light"
                  onClick={generatePreview}
                >
                  Preview
                </Button>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col className="pl-2">
          <LoadingOverlay active={loading} spinner>
            <Card outline color="primary" className="border mt-3 mb-3">
              <CardBody>
                <h6 className="text-primary">Preview</h6>
                {!processedVars && !validationMessages && (
                  <div className="d-flex p-4 justify-content-center text-primary">
                    No preview available.
                  </div>
                )}

                {validationMessages?.length > 0 && (
                  <ul className="m-2">
                    <span className="text-primary">{"Validation Message"}</span>
                    {validationMessages.map((item, index) => (
                      <li key={index}>
                        {item.name} - {item.message}
                      </li>
                    ))}
                  </ul>
                )}
                {processedVars?.length > 0 && (
                  <>
                    <span className="text-primary fw-semibold">{"Resolved Vars"}</span>
                    <ul className="m-2">

                      {processedVars?.map((item, index) => (
                        <li key={index} className="pl-3">
                          <div key={item.name}>
                            <Row>
                              <Col sm={3}> {item.name}
                              </Col>
                              <Col> {item.value}
                              </Col>
                            </Row>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </CardBody>
              {/* {props.type == "staged" && (
                <div className="modal-footer p-3">
                  <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={confirmPublish}
                    disabled={!processedVars && !validationMessages}
                  >
                    Publish
                  </Button>
                </div>
              )} */}
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
    </>
  )
}

export default Preview
