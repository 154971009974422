import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import AuthGrantFlowDetails from "./AuthGrantFlowDetails"
import IdpForm from "./IdpForm"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

const AuthGrantFlow = () => {
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [data, setData] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [])

  const openEditForm = () => {
    navigate("/auth-config/new")
  }

  const openSAMLEditForm = () => {
    navigate("/auth-config-saml/new")
  }

  const getData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "idp", { credentials: "include" })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      if (jsonData) {
        setIsNew(false)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <QdContainer>
      {loading && <div>Loading....</div>}
      {!isNew && !loading && <AuthGrantFlowDetails />}
      {isNew && !loading && !data && (
        <div>
          <Card>
            <CardBody className="p-3">
              <div className="text-center mt-3">
                <span className="mt-3">No configuration found</span>
                <div className="mt-4">
                  <Dropdown
                    isOpen={singlebtn}
                    toggle={() => setSinglebtn(!singlebtn)}
                  >
                    <DropdownToggle tag="button" className="btn btn-primary">
                      Create New <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="text-primary">
                        Select Flow Type
                      </DropdownItem>
                      <div className="dropdown-divider"></div>
                      <DropdownItem onClick={openEditForm}>OIDC</DropdownItem>
                      <DropdownItem onClick={openSAMLEditForm}>
                        SAML
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {/* <Button type="button" color="primary" className="w-md" onClick={openEditForm}>
                                    Create New
                            </Button> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </QdContainer>
  )
}

export default AuthGrantFlow
