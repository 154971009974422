import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Card, CardBody, Col, Collapse, Row, Table } from "reactstrap"
import classnames from "classnames"
import { map } from "lodash"
import { Tbody, Thead } from "react-super-responsive-table"
import Cookies from "js-cookie"

const OpenDockWarehouse = () => {
  const { locationId, locationName } = useParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()

  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "opendock/configmapping/" + locationId + "/help",
        { credentials: "include" }
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
  }

  return (
    <QdContainer>
      <Card>
        <CardBody>
          <Col>
            <div className="float-start">
              <h5 className="text-primary">Warehouse Configurations</h5>
              <h6>{locationName}</h6>
            </div>
          </Col>
          <Col>
            <div className="float-end">
              <div className="d-flex gap-2">
                <div className="text-sm">
                  <span
                    onClick={() => {
                      loadData()
                    }}
                    className="pointer text-primary"
                  >
                    <i className="fas fa-sync-alt" />
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </CardBody>
      </Card>
      {loading && <div>Loading..</div>}
      {!loading && data && (
        <Card>
          <CardBody>
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col1,
                    })}
                    type="button"
                    onClick={t_col1}
                    style={{ cursor: "pointer" }}
                  >
                    Load Types
                  </button>
                </h2>

                <Collapse isOpen={col1} className="accordion-collapse">
                  <div className="accordion-body">
                    {data.load_types.map((item, index) => (
                      <ul
                        key={index}
                        className="border p-2 "
                        style={{ listStyleType: "none" }}
                      >
                        <li>
                          <Row>
                            <Col md={3}>
                              <span className="text-muted">Direction</span>
                            </Col>
                            <Col md={9}>{item.direction}</Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <span className="text-muted">Name</span>
                            </Col>
                            <Col md={9}>{item.name}</Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <span className="text-muted">Operation</span>
                            </Col>
                            <Col md={9}>{item.operation || "-"}</Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <span className="text-muted">Docks</span>
                            </Col>
                            <Col md={9}>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Active</th>
                                    <th>Allow Carrier Scheduling</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.docks.map((item, idx) => (
                                    <tr key={idx}>
                                      <td width="50%">{item.name}</td>
                                      <td>{item.isActive ? "Yes" : "No"}</td>
                                      <td>
                                        {item.allowCarrierScheduling
                                          ? "Yes"
                                          : "No" + ""}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </li>
                      </ul>
                    ))}
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col2,
                    })}
                    type="button"
                    onClick={t_col2}
                    style={{ cursor: "pointer" }}
                  >
                    Custom Fields
                  </button>
                </h2>

                <Collapse isOpen={col2} className="accordion-collapse">
                  <div className="accordion-body">
                    <table className="table border stripped">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Label</th>
                          <th>Required</th>
                          <th>Drop-down values</th>
                        </tr>
                      </thead>
                      {data.custom_fields.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.type}</td>
                          <td>{item.label}</td>
                          <td>{item.required ? "Yes" : "No"}</td>
                          <td>{item.dropDownValues?.join(", ")}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Collapse>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </QdContainer>
  )
}

export default OpenDockWarehouse
