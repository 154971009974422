import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import PublishedData from "./PublishedData"
import StaggedData from "./StaggedData"
import { Button, Card, CardBody } from "reactstrap"
import Cookies from "js-cookie"
import { get } from "utils/apiClient"

const OpenDockTemplate = () => {
  const [publishedData, setPublishedData] = useState()
  const [stagedData, setStagedData] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const location = useLocation()
  const [isNew, setIsNew] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [data, setData] = useState()
  const [showStaggedData, setShowStaggedData] = useState(false)

  const [currentData, setCurrentData] = useState()
  const [mlocationId, setMlocationId] = useState()
  const [locationId, setLocationId] = useState()
  const [locationName, setLocationName] = useState()
  const { state } = location || {}

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)

    setLocationId(state?.locationId)
    setLocationName(state?.locName)
    setMlocationId(state?.m_loc_id)

    setLoading(false)
    getEmailTemplate()
    setIsEditing(false)
  }, [])

  const getEmailTemplate = async () => {
    try {
      setLoading(true)
      const mLocId = state.m_loc_id
      

      let response = await get("opendock/configmapping/" + mLocId)
      if (response) {
        if (response.published && response.has_tenant_cfg) {
          setPublishedData(response.published)
          setSelectedTemplate("published")
          setShowStaggedData(false)
        }

        if (response.staged) {
          setStagedData(response.staged)
        }

        setIsNew(false)
      }
      setCurrentData(publishedData)
      setLoading(false)
    }
    catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }
  const goBack = () => {
    navigate("/location-management")
  }

  const showStagged = () => {
    if (selectedTemplate !== "published") setSelectedTemplate("published")
    else setSelectedTemplate("staged")
  }

  return (
    <QdContainer>
      {loading && <div>Loading..</div>}
      {selectedTemplate == "published" &&
        !showStaggedData &&
        !loading &&
        publishedData && (
          <PublishedData
            locationId={locationId}
            showStagged={showStagged}
            m_loc_id={mlocationId}
            getEmailTemplate={getEmailTemplate}
            locationName={locationName}
          />
        )}
      {selectedTemplate !== "published" && !loading && stagedData && (
        <StaggedData
          locationId={locationId}
          m_loc_id={mlocationId}
          showPublished={showStagged}
          data={stagedData}
          hasPublished={selectedTemplate == "published"}
          locationName={locationName}
        />
      )}
      {!stagedData && !publishedData && !loading && (
        <Card>
          <CardBody className="text-center">
            <p>No configuration found.</p>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={goBack}
            >
              Back
            </Button>
          </CardBody>
        </Card>
      )}
    </QdContainer>
  )
}

export default OpenDockTemplate
