import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Form, Input, Label, Modal, Row } from 'reactstrap'
import VarData from './VarData'
import LoadingOverlay from 'react-loading-overlay'
import Success from 'pages/NotificationModal/Success'
import { get, post } from 'utils/apiClient'
import Preview from './Preview'

function NewConfig({ m_loc_id, configData, cancel }) {

  const [varMaps, setVarMaps] = useState(configData?.var_map || [])
  const [loading, setLoading] = useState(false)
  const [mapType, setMapType] = useState('locationId')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isPreviewed, setIsPreviewed] = useState(false)


  useEffect(() => {
  })

  const save = async (values) => {
    setLoading(true)
    try {

      var obj = {
        "var_map": varMaps,
        "config": configData.config,
        "tms_loc_id": values.tms_loc_id,
        "tms_loc_nm": values.tms_loc_nm,
        "m_loc_id": m_loc_id
      }
      const response = await post('location-management/opendock', obj)
      setLoading(false)
      setShowSuccessModal(true)
      
    } catch (error) {
      console.error(error)
      setLoading(false)
    }

  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      tms_loc_id: "",
      tms_loc_nm: "",
      address: {
        addr_line: "",
        city: "",
        state: "",
        zip: "",
      },
      mapType: mapType,
    },



    onSubmit: values => {
      save(values)
    },
  })

  const handleRadioChange = value => {
    setMapType(value)
  }

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  const handleSetVarMaps = (varmaps) => {
    setVarMaps(null)
    setVarMaps([...varmaps])
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          togSuccessModal()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !!"
          close={cancel}
        />
      </Modal>
      <LoadingOverlay active={loading} spinner>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row className="mb-4">
            <Col md={3}>
              <Label> Location Map Type</Label>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="mapType"
                      id="exampleRadios1"
                      value="locationId"
                      defaultChecked
                      onChange={e => {
                        handleRadioChange(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Location Id
                    </label>
                    {"  "}
                  </div>
                </Col>
                <Col>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="mapType"
                      id="exampleRadios2"
                      value="address"
                      onChange={e => {
                        handleRadioChange(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Address
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <Label>Location Name</Label>
            </Col>
            <Col md={6}>
              {/* <Input className='form-control' name="tms_loc_nm" placeholder='Enter the location Id' onChange={e => validate(e.target.value)} /> */}
              <Input
                type="text"
                className="form-control"
                name="tms_loc_nm"
                id="horizontal-tms_loc_nm-Input"
                placeholder="Enter the location name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.tms_loc_nm || ""}
                invalid={
                  validation.touched.tms_loc_nm &&
                    validation.errors.tms_loc_nm
                    ? true
                    : false
                }
              />
            </Col>
          </Row>
          {mapType == "locationId" && (
            <Row className="mb-4">
              <Col md={3}>
                <Label>Location Id</Label>
              </Col>
              <Col md={6}>
                {/* <Input className='form-control' name="tms_loc_id"  onChange={e => validate(e.target.value)} /> */}
                <Input
                  type="text"
                  className="form-control"
                  name="tms_loc_id"
                  id="horizontal-tms_loc_id-Input"
                  placeholder="Enter the new TMS location id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.tms_loc_id || ""}
                  invalid={
                    validation.touched.tms_loc_id &&
                      validation.errors.tms_loc_id
                      ? true
                      : false
                  }
                />
              </Col>
            </Row>
          )}
          {mapType == "address" && (
            <div>
              <Row className="mb-4">
                <Col md={3}>
                  <Label>Address</Label>
                </Col>
                <Col md={6}>
                  {/* <Input className='form-control' name="address.addr_line" placeholder='Enter the address line' onChange={e => validate(e.target.value)} /> */}
                  <Input
                    type="text"
                    className="form-control"
                    name={"address.addr_line"}
                    id="horizontal-address.addr_line-Input"
                    placeholder="Enter the address line"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values?.address?.addr_line || ""}
                    invalid={
                      validation.touched.address?.addr_line &&
                        validation.errors.address?.addr_line
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={3}></Col>
                <Col md={6}>
                  {/* <Input className='form-control' name="address.city" placeholder='Enter the city' onChange={e => validate(e.target.value)} /> */}
                  <Input
                    type="text"
                    className="form-control"
                    name="address.city"
                    id="horizontal-address.city-Input"
                    placeholder="Enter the City"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address?.city || ""}
                    invalid={
                      validation.touched.address?.city &&
                        validation.errors.address?.city
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={3}></Col>
                <Col md={6}>
                  {/* <Input className='form-control' name="address.city" placeholder='Enter the city' onChange={e => validate(e.target.value)} /> */}
                  <Input
                    type="text"
                    className="form-control"
                    name="address.state"
                    id="horizontal-state-Input"
                    placeholder="Enter the state"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address?.state || ""}
                    maxLength={2}
                    invalid={
                      validation.touched.address?.state &&
                        validation.errors.address?.state
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={3}></Col>
                <Col md={6}>
                  {/* <Input className='form-control' name="address.zip" placeholder='Enter the city' onChange={e => validate(e.target.value)} /> */}
                  <Input
                    type="text"
                    className="form-control"
                    name="address.zip"
                    id="horizontal-state-Input"
                    placeholder="Enter the zip"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address?.zip || ""}
                    maxLength={2}
                    invalid={
                      validation.touched.address?.zip &&
                        validation.errors.address?.zip
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>

            </div>
          )}
          {varMaps?.length > 0 && <VarData vars={varMaps} setVars={handleSetVarMaps} labelWidth={3} isBase={false}/>}

          {!loading && configData &&
                <Card>
                     <CardBody>
                            <Preview m_loc_id={m_loc_id} cfgType="staged" configData = {configData} varMaps={varMaps} is_tenant_specs={true} setIsPreviewed={setIsPreviewed}/>
                        </CardBody>
                </Card>}

          <div className="modal-footer">
            <button
              id="addValButton"
              type="submit"
              className="btn btn-primary m-3"
              disabled={!isPreviewed}
            >
              Save
            </button>
            {" "}
            <button
              type="button"
              className="btn btn-secondary me-3"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>

        </Form>
      </LoadingOverlay>

    </React.Fragment>
  )

}

export default NewConfig