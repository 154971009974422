import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Container } from "reactstrap"
import Error from "./Error"
import StopDetail from "./StopDetail"
import SuccessPage from "./Success"
import Cookies from "js-cookie"
import { get } from "utils/apiClient"

const AcceptAppointment = () => {
  const [hasError, setHasError] = useState(false)
  const [pathParam, setPathParam] = useSearchParams()
  const [stopId, setStopId] = useState(pathParam.get("stop_id"))
  const [reviewId, setReviewId] =  useState(pathParam.get("review_ctx_id"))
  const [stop, setStop] = useState()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [success, setSuccess] = useState(false)


  useEffect(() => {
    console.log(pathParam)
    console.log(pathParam.get("stop_id"))
    console.log(pathParam.get("review_ctx_id"))
    setStopId(pathParam.get("stop_id"))
    setReviewId(pathParam.get("review_ctx_id"))
    localStorage.setItem('redirectUrl', (window.location.pathname + window.location.search).substring(1));

    fetchData()
  }, [])

  const successResult = () => {
    setSuccess(true)
  }

  const fetchData = async () => {
    setLoading(true)
    try {

      var url = "stop/" + stopId
      console.log("stop id = " + stopId)
      const response = await get(url)

   
      setStop(response.data)
      console.log(stop)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error.message)
    }
  }

  return (
    <React.Fragment>
      {!hasError && !success && (
        <div className="page-content">
          <Container fluid>
            {loading && <div>Loading..</div>}
            {!loading && (
              <StopDetail data={stop} reviewId={reviewId} successResult={successResult} />
            )}
          </Container>
        </div>
      )}
      {hasError && <Error errorMessage={errorMessage} />}
      {success && <SuccessPage />}
    </React.Fragment>
  )
}

export default AcceptAppointment
