import React from "react"
import { useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { Col, Input, Label, Modal, Row, UncontrolledTooltip } from "reactstrap"

import validator from "validator"
import Cookies from "js-cookie"

const LocationNameCell = props => {
  const [loading, setLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [locationId, setLocationId] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  const copy = () => {
    setShowModal(!showModal)
  }

  const save = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "locations"

      console.log(props.row)

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tmsLocationId: locationId,
          qdLocationId: props.row.original.quedLocationId,
          locationName: props.row.original.tLocationName,
        }),
        credentials: "include",
      }

      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setErrorMessage(res.message)
        setLoading(false)
        throw new Error(res.message)
      }
      const res = await response.json()
      setErrorMessage(null)
      console.log("hello success" + res.message)
      setLoading(false)
      props.refreshTable()
    } catch (error) {
      console.error("Error:" + error.message)
      setLoading(false)
      console.error(error.message)
    }
  }

  const cancel = () => {}

  function tog_center(status) {
    setShowModal(!showModal)
  }

  const validate = value => {
    setLocationId(validator.trim(value))
  }

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="md"
      >
        <LoadingOverlay active={loading} spinner>
          <div className="modal-header">
            <h5 className="modal-title mt-0">New Location Id</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowModal(false)}>
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group mb-4">
              <Label>Location Name</Label>
              <Input
                className="form-control d-block"
                value={props.value}
                disabled
              />
            </div>
            <div className="form-group mb-4">
              <Label>Location Id</Label>
              <Input
                className="form-control d-block"
                onChange={e => validate(e.target.value)}
                value={locationId}
              />
              {errorMessage && (
                <Row>
                  <span className="text-sm text-danger">{errorMessage}</span>
                </Row>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => save()}
              disabled={locationId.length == 0}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secodnary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </LoadingOverlay>
      </Modal>
      <Row>
        <Col md={11}>{props.value}</Col>
        <Col md={1}>
          {props.row.original.locationId && (
            <i
              className="fas fa-copy d-block font-size-16 text-primary justify-content-end"
              id={"copyLocation" + props.row.id}
              onClick={() => copy()}
            />
          )}
          {props.row.original.locationId && (
            <UncontrolledTooltip
              placement="top"
              target={"copyLocation" + props.row.id}
            >
              Create new location Id
            </UncontrolledTooltip>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default LocationNameCell
