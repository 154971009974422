import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  Row,
  Table,
} from "reactstrap"

import Cookies from "js-cookie"

import { useFormik } from "formik"
import * as Yup from "yup"
import Success from "pages/NotificationModal/Success"
import LoadingOverlay from "react-loading-overlay"
import ValidationData from "./ValidationData"
import VarData from "./VarData"
import Confirmation from "pages/NotificationModal/Confirmation"
import EmailRule from "./EmailRule"

const var_types = [
  { label: "PATH", value: "PATH" },
  { label: "FMT", value: "FMT" },
  { label: "EXPR", value: "EXPR" },
  { label: "LIT", value: "LIT" },
]

const EditForm = props => {
  const api_url = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [vars, setVars] = useState(props.data?.vars || [])
  const [formdata, setFormdata] = useState(props.data?.vars || [])
  const [selectedTemplate, setSelectedTemplate] = useState(
    props.data.htmlTemplate || ""
  )
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [validations, setValidations] = useState(props.data?.validations || [])

  useEffect(() => {
    //setFormDataForVars(selectedTemplate)
    loadAllTemplate()
    console.log(formdata)
  }, [selectedTemplate])

  const loadAllTemplate = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "email/templates?cfgType=published",
        { credentials: "include" }
      )
      if (!response.ok) {
        throw new Error("Request failed")
      }
      const jsonData = await response.json()
      if (jsonData) {
        setTemplates(jsonData)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const saveConfirm = () => {
    if (
      !validation.values.tos ||
      validation.values.tos?.length == 0 ||
      !validation.values.from ||
      validation.values.from?.length == 0
    ) {
      setShowWarningModal(true)
    } else {
      save()
    }
  }

  const save = async () => {
    try {
      setLoading(true)

      const sig = validation.values.sig_identifier_regex
        ? validation.values.sig_identifier_regex.trim()
        : null
      const conf = validation.values.confirmation_msgs
        ? validation.values.confirmation_msgs.trim()
        : null
      const toAddresses = validation.values.tos
        ? validation.values.tos.trim()
        : null
      const ccAddresses = validation.values.ccs
        ? validation.values.ccs.trim()
        : null
      const fromAddress = validation.values.from
        ? validation.values.from.trim()
        : null
      var data = {
        htmlTemplate: selectedTemplate,
        vars: formdata,
        confirmation_msgs: conf && conf.length > 0 ? conf.split("\n") : [],
        sig_identifier_regex: sig && sig.length > 0 ? sig.split("\n") : [],
        validations: validations,
        to: toAddresses,
        cc: ccAddresses,
        from: fromAddress,
      }
      const token = Cookies.get("idToken")

      const config = {
        method: props.locationId ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
      const response = await fetch(
        api_url + "email/templatemapping/" + props.m_loc_id,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
      setShowSuccessModal(true)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const setFormDataForVars = async value => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "email/templates/" + value, {
        credentials: "include",
      })
      if (!response.ok) {
        throw new Error("Request failed")
      }
      const jsonData = await response.json()
      if (jsonData) {
        setVars(jsonData.vars)
        setFormdata(jsonData.vars)
        setValidations(jsonData.validations)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const handleTemplateChange = async value => {
    setFormDataForVars(value)
    setSelectedTemplate(value)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      htmlTemplate: selectedTemplate,
      confirmation_msgs:
        props.data.confirmation_msgs && props.data.confirmation_msgs?.length > 0
          ? props.data.confirmation_msgs.join("\n")
          : null,
      sig_identifier_regex:
        props.data.sig_identifier_regex?.length > 0
          ? props.data.sig_identifier_regex.join("\n")
          : null,
      tos: props.data.to ? props.data.to : null,
      ccs: props.data.cc ? props.data.cc : null,
      from: props.data.from ? props.data.from : null,
    },
    validationSchema: Yup.object({
      htmlTemplate: Yup.string().required("Please select html template"),
      confirmation_msgs: Yup.string().required(
        "Please enter the confirmation message"
      ),
      sig_identifier_regex: Yup.string().required(
        "Please enter the sign indentifier"
      ),
      vars: Yup.string().required("Pls enter the value"),
    }),

    onSubmit: values => {
      saveConfirm(values)
    },
  })

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  const togWarningModal = () => {
    setShowWarningModal(!showWarningModal)
  }

  return (
    <>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          togSuccessModal()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !!"
          close={props.cancel}
        />
      </Modal>
      <Modal
        isOpen={showWarningModal}
        toggle={() => {
          togWarningModal()
        }}
        centered
        size="md"
      >
        <Confirmation
          message="To or From address missing in the configuration, do you want to proceed?"
          confirm={save}
          cancel={togWarningModal}
        />
      </Modal>
      <Form
        onSubmit={e => {
          e.preventDefault()
          saveConfirm()
          return false
        }}
      >
        <LoadingOverlay active={loading} spinner>
          <CardBody>
            <Row>
              <h5 className="text-primary">Edit Email Configuration</h5>
            </Row>
            <Row>
              <Card outline color="primary" className="border mt-3 mb-3">
                {templates && (
                  <CardBody className="p-2 cardBody">
                    <Row className="mb-2 font-weight-100">
                      <Col sm="2" className={"text-muted"}>
                        <span>Email Tempalte:</span>
                      </Col>
                      <Col sm="8">
                        <select
                          className="form-control"
                          name="htmlTemplate"
                          onChange={e => {
                            e.preventDefault()
                            handleTemplateChange(e.target.value)
                          }}
                          onBlur={validation.handleBlur}
                          value={selectedTemplate}
                        >
                          <option value="">Select Template</option>
                          {templates.map((item, index) => (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {validation.touched.htmlTemplate &&
                        validation.errors.htmlTemplate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.htmlTemplate}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row className="mb-3">
                      <VarData
                        vars={formdata}
                        isEdit={true}
                        isNew={false}
                        setVars={setFormdata}
                        key={formdata}
                      />
                    </Row>
                    <div className="border-bottom mb-3"></div>
                    <Row>
                      <Col sm="2" className={"text-muted"}>
                        <span>Confirmation Message:</span>
                      </Col>
                      <Col md="8">
                        <textarea
                          value={validation.values.confirmation_msgs}
                          className="form-control"
                          name="confirmation_msgs"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.confirmation_msgs &&
                        validation.errors.confirmation_msgs ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmation_msgs}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row className="mt-1 mb-2">
                      <Col sm="2" className={"text-muted"}>
                        <span>Signature:</span>
                      </Col>
                      <Col md="8">
                        <textarea
                          value={validation.values.sig_identifier_regex}
                          className="form-control"
                          name="sig_identifier_regex"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.sig_identifier_regex &&
                        validation.errors.sig_identifier_regex ? (
                          <FormFeedback type="invalid">
                            {validation.errors.sig_identifier_regex}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-3"></div>
                    <Row className="mt-2 mb-2">
                      <ValidationData
                        validations={validations}
                        isEdit={true}
                        setValidations={setValidations}
                        key={validations}
                      />
                    </Row>
                    <div className="border-bottom mb-3"></div>
                    <Row className="mt-2">
                      <Col sm="2" className={"text-muted"}>
                        <span>From:</span>
                      </Col>
                      <Col md="8">
                        <textarea
                          value={validation.values.from}
                          className="form-control"
                          name="from"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          placeholder="Enter comma separated email addresses/domains"
                        />
                        {validation.touched.from && validation.errors.from ? (
                          <FormFeedback type="invalid">
                            {validation.errors.from}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Row>
            {/* <Row className='mt-2'>
                            <EmailRule isEdit={true} data={props.data} />
                        </Row> */}
          </CardBody>
          <div className="modal-footer d-flex justify-content-end gap-3">
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={props.cancel}>
              Cancel
            </Button>
          </div>
        </LoadingOverlay>
      </Form>
    </>
  )
}

export default EditForm
