import QdContainer from 'components/Common/QdContainer'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardSubtitle, Col, Modal, Row } from 'reactstrap'
import { get, put, post } from 'utils/apiClient'
import VarData from './VarData'
import Success from 'pages/NotificationModal/Success'
import LoadingOverlay from 'react-loading-overlay'
import Preview from './Preview'

function LocationConfig() {
    const [loading, setLoading] = useState(false)
    const [configData, setConfigData] = useState(null)
    const [varMaps, setVarMaps] = useState([])
    const navigate = useNavigate()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [isPreviewed, setIsPreviewed] = useState(false)

    const { m_loc_id, location_name } = useParams()

    useEffect(() => {
        console.log("m_loc_id ==" + m_loc_id + " location name ==" + location_name)
        fetchConfig()
    }, [])

    const fetchConfig = async () => {
        setLoading(true)
        try {
            const response = await get("location-management/opendock/" + m_loc_id)
            setConfigData(response)
            setVarMaps(response.var_map)
            console.log(response)
            setLoading(false)

        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const cancel = () => {
        navigate('/location-management')
    }

    const handleSetVarMaps = (varmaps) => {
        setIsPreviewed(false)
        setVarMaps(null)
        setVarMaps([...varmaps])
    }

    useEffect(() => {
        console.log("resetting the var data table")
    }, [varMaps])

    const save = async () => {
        setLoading(true)
        try {
            var obj = {
                "var_map": varMaps,
                "config": configData.config,
                "m_loc_id": m_loc_id
            }
            const response = await post('location-management/opendock', obj)
            setLoading(false)
            setShowSuccessModal(true)
        } catch (error) {
            console.error(error);
            setLoading(false)
        }

    }

    const togSuccessModal = () => {
        setShowSuccessModal(!showSuccessModal)
    }

    const publish = () => {

    }

    return (
        <React.Fragment>
            <Modal
                isOpen={showSuccessModal}
                toggle={() => {
                    togSuccessModal()
                }}
                centered
                size="md"
            >
                <Success
                    message="Configuration saved successfully !!"
                    close={cancel}
                />
            </Modal>
            <QdContainer>
                <>
                <Card className='p-3'>
                    <Row>
                        <Col>
                            <span className='text-primary text-size-16 m-2'>Opendock Location Configuration</span>
                        </Col>
                    </Row>
                    <CardSubtitle className='m-2'> {location_name}</CardSubtitle>
                </Card>
                {loading && <div>Loading...</div>}
                {!loading && configData && <Card>
                    <LoadingOverlay active={loading} spinner>
                        <CardBody>
                            <VarData vars={varMaps} labelWidth={2} setVars={handleSetVarMaps} />
                           
                        </CardBody>
                        
                    </LoadingOverlay>
                   
                </Card>}
                {!loading && configData &&
                <Card>
                     <CardBody>
                            <Preview m_loc_id={m_loc_id} cfgType="staged" configData = {configData} varMaps={varMaps} is_tenant_specs={true} setIsPreviewed={setIsPreviewed}/>
                           
                        </CardBody>
                </Card>}
                 {!loading && <div className="modal-footer">
                 <Button color='primary' onClick={save} disabled={!isPreviewed} className='m-2'>Save</Button>
                 <Button color='secondary' onClick={cancel} className="btn btn-secondary me-3">cancel</Button>
             </div>}
             </>
            </QdContainer>
        </React.Fragment>

    )
}

export default LocationConfig