import QdContainer from "components/Common/QdContainer"
import Success from "pages/NotificationModal/Success"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import TemplateValidations from "./TemplateValidations"
import TemplateVars from "./TemplateVars"
import LoadingOverlay from "react-loading-overlay"
import Cookies from "js-cookie"

const CreateTemplate = props => {
  const [loading, setLoading] = useState(false)
  const [variables, setVariables] = useState(props.data?.vars || [])
  const [templateName, setTemplateName] = useState()
  const [desc, setDesc] = useState()
  const [subjectHtml, setSubjectHtml] = useState("Sample subject")
  const [safeSubjectHtml, setSafeSubjectHtml] = useState("Sample subject")
  const [contentHtml, setContentHtml] = useState()
  const [validations, setValidations] = useState()
  const [safeContentHtml, setSafeContentHtml] = useState()

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [errors, setErrors] = useState({})

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const close = () => {
    setShowSuccessModal(false)
    navigate("/email-templates")
  }

  useEffect(() => {
    setVariables([])

    fetch("/sampleContent.txt")
      .then(response => {
        if (response.ok) {
          return response.text()
        }
      })
      .then(text => {
        setSafeContentHtml(text)
        setContentHtml(text)
      })
      .catch(error => console.log(error))
  }, [])

  const save = async () => {
    console.log("saving")
    const encoder = new TextEncoder()
    const uint8ArraySubject = encoder.encode(safeSubjectHtml)
    const base64StringSubject = btoa(String.fromCharCode(...uint8ArraySubject))

    const uint8ArrayContent = encoder.encode(safeContentHtml)
    const base64StringContent = btoa(String.fromCharCode(...uint8ArrayContent))

    var obj = {
      name: templateName,
      desc: desc,
      content_body: base64StringContent,
      content_subject: base64StringSubject,
      vars: variables,
      validations: validations,
    }

    try {
      setLoading(true)

      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      //const response = await fetch(,  { credentials: "include" })

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        credentials: "include",
      }
      const response = await fetch(api_url + "email/templates", config)

      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
      setShowSuccessModal(true)
      setLoading(false)
      console.log("Saved")
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const handleTemplateNameChange = event => {
    const { name, value } = event.target

    setTemplateName(value)
  }

  const handleDescChange = event => {
    const { name, value } = event.target
    setDesc(value)
  }

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  const sanitizeHTML = inputHTML => {
    // Remove any tag that contains attributes starting with "on" or any potentially dangerous attribute
    let sanitizedHTML = inputHTML.replace(
      ///<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*>[^<]*<\/[^>]*>|<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*\/?>/gi,
      /<script[^>]*>[\s\S]*?<\/script>|<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*>[^<]*<\/[^>]*>|<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*\/?>/gi,
      ""
    )

    // Remove <script> tags entirely
    const noScriptHTML = sanitizedHTML.replace(
      /<script[^>]>[\s\S]?<\/script>/gi,
      ""
    )

    console.log(noScriptHTML)

    return noScriptHTML
  }

  const handleBodyContentChange = e => {
    const input = e.target.value

    setContentHtml(input)
    let safeContent = sanitizeHTML(input)

    setSafeContentHtml(safeContent)
    console.log(safeContent)
  }

  const handleSubjectChange = e => {
    const input = e.target.value
    setSubjectHtml(input)

    const cleanSubjectHtml = DOMPurify.sanitize(input)
    setSafeSubjectHtml(cleanSubjectHtml)
  }

  const validate = () => {
    const newErrors = {}
    const regex = /^[a-zA-Z0-9_ .]*$/

    if (!templateName) {
      newErrors.templateName = "Template name is required."
    } else if (!regex.test(templateName)) {
      newErrors.templateName =
        "Template name is invalid, only alpha numeric characters with space or underscore is allowed"
    }

    if (!desc) {
      newErrors.desc = "Description is required."
    } else if (!regex.test(desc)) {
      newErrors.desc =
        "Description is invalid, only alpha numeric characters with space or underscore is allowed"
    }

    if (!variables || variables.length == 0) {
      newErrors.variables = "Please add required variables."
    }

    return newErrors
  }

  const handleSubmit = () => {
    setLoading(true)
    setErrors(null)
    const validationErrors = validate()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      setLoading(false)
    } else {
      setErrors(null)
      save()
    }
  }

  return (
    <QdContainer>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          togSuccessModal()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !!"
          close={close}
        />
      </Modal>
      <Row>
        <LoadingOverlay active={loading} spinner>
          <Card>
            <CardBody>
              <CardTitle className="mb-4 text-primary">
                Create New Email Template
              </CardTitle>

              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                  return false
                }}
              >
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-2 col-form-label"
                      >
                        Name *
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="name"
                          id="horizontal-firstname-Input"
                          placeholder="Enter name of template"
                          onChange={e => {
                            e.preventDefault()
                            handleTemplateNameChange(e)
                          }}
                          value={templateName}
                          invalid={errors?.templateName ? true : false}
                        />
                        {errors?.templateName && (
                          <FormFeedback type="invalid">
                            {errors.templateName}
                          </FormFeedback>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label htmlFor="desc" className="col-sm-2 col-form-label">
                        Description *
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="desc"
                          id="horizontal-firstname-Input"
                          placeholder="Enter description of template"
                          onChange={handleDescChange}
                          value={desc || ""}
                          invalid={errors?.desc ? true : false}
                        />
                        {errors?.desc && (
                          <FormFeedback type="invalid">
                            {errors.desc}
                          </FormFeedback>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <TemplateVars
                        setVars={setVariables}
                        isNew={true}
                        isEdit={true}
                      />
                      {errors?.variables && (
                        <p className="text-xs text-danger">
                          {errors.variables}
                        </p>
                      )}
                    </Row>
                  </CardBody>
                </Card>
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody>
                    <Row>
                      <TemplateValidations
                        setValidations={setValidations}
                        vars={variables}
                        isEdit={true}
                      />
                    </Row>
                  </CardBody>
                </Card>
                <Row className="mb-4">
                  <Label htmlFor="desc" className="col-sm-2 col-form-label">
                    Subject Content
                  </Label>
                  <Col sm={6}>
                    <Input
                      value={subjectHtml}
                      type="text"
                      id="textarea"
                      // style={{ height: '100px' }}
                      onChange={handleSubjectChange}
                      placeholder="This textarea has a limit of 225 chars."
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Label htmlFor="desc" className="col-sm-2 col-form-label">
                    Body content
                  </Label>
                  <Col sm={6}>
                    <Input
                      value={contentHtml}
                      type="textarea"
                      id="textarea"
                      style={{ height: "400px" }}
                      onChange={handleBodyContentChange}
                      placeholder="This textarea has a limit of 225 chars."
                    />
                  </Col>
                </Row>

                <div className="modal-footer">
                  <Button
                    color="primary"
                    className="btn btn-primary me-2"
                    type="submit"
                  >
                    Save
                  </Button>
                  <button
                    type="button"
                    className="btn btn-secodnary"
                    onClick={close}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </LoadingOverlay>
      </Row>
    </QdContainer>
  )
}

export default CreateTemplate
