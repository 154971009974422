import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Col, Modal, UncontrolledTooltip } from "reactstrap"
import TenantCustomisationTableContainer from "./TenantCustomisationTableContainer"
import EditTenantCustomisation from "./EditTenantCustomisation"
import Success from "pages/NotificationModal/Success"
import LoadingOverlay from "react-loading-overlay"
import { getValue } from "@mui/system"
import Cookies from "js-cookie"
import { get } from "utils/apiClient"

const TenantCustomisation = () => {
  const [loading, setLoading] = useState(false)
  const [custdata, setCustData] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [deletingData, setDeletingData] = useState(null)
  const [editingData, setEditingData] = useState(null)
  const api_url = process.env.REACT_APP_API_URL

  const edit = row => {
    console.log(row.original)
    setEditingData(row.original)
    setShowEditModal(true)
  }

  const cancelEdit = () => {
    setShowEditModal(false)
    setEditingData(null)
  }

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal)
  }

  const get_value = data => {
    if (data.current_value) {
      var xpath_key = data.xpath
      console.log(xpath_key)
      console.log(data.current_value[xpath_key])
      return data.current_value[xpath_key]
        ? data.current_value[xpath_key] + ""
        : ""
    }

    return data.default_value == "NONE" ? "" : data.default_value +""
  }

  const openDeleteConfirm = row => {
    setDeletingData(row.original)
    setShowDeleteConfirm(true)
  }

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "display_name", //access nested data with dot notation
          Header: "Name",
          width: 80,
        },

        {
          Header: "Value",
          width: 100,
          Cell: ({ row }) => <div>{get_value(row.original)}</div>,
        },
        {
          Header: "Action",
          width: 20,
          Cell: ({ row }) => (
            <div className="d-flex justify-content-center ">
              <i
                className="fas fa-pencil-alt text-primary p-2"
                id={"edittooltip" + row.original.id}
                onClick={() => edit(row)}
              />
              <UncontrolledTooltip
                placement="bottom"
                target={"edittooltip" + row.original.id}
              >
                Edit
              </UncontrolledTooltip>
              {row.original.current_value &&
                row.original.current_value[row.original.xpath] && (
                  <i
                    className="fas fa-trash-alt d-flex text-red p-2"
                    id={"deletetooltip" + row.original.id}
                    onClick={() => openDeleteConfirm(row)}
                  />
                )}
              {row.original.current_value &&
                row.original.current_value[row.original.xpath] && (
                  <UncontrolledTooltip
                    placement="top"
                    target={"deletetooltip" + row.original.id}
                  >
                    Delete
                  </UncontrolledTooltip>
                )}
            </div>
          ),
        },
      ],
      []
    )
  }

  const columns = getColumns()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const response = await get("tenant-customisation")
      console.log(response)
      setCustData(response)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const onSuccessHandle = () => {
    setShowEditModal(false)
    setShowDeleteConfirm(false)
    setShowSuccessModal(true)
  }

  const closeSuccess = () => {
    setShowSuccessModal(false)
    setEditingData(null)
    setDeletingData(null)
    loadData()
  }

  const toggleDeleteConfirm = () => {
    setShowDeleteConfirm(!showDeleteConfirm)
    if (!showDeleteConfirm) {
      setDeletingData(null)
    }
  }

  const remove = async () => {
    var config = deletingData?.current_value
    config = deleteKeyFromNestedObject(config, deletingData?.xpath)

    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var http_method = "PUT"

      var data = {
        customisation_type: deletingData.customisation_type,
        config: config,
      }

      const http_config = {
        method: http_method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      }

      const response = await fetch(
        api_url + "tenant-customisation",
        http_config
      )
      if (!response.ok) {
        throw new Error("Request failed")
      }
      ///const jsonData = await response.json()
      setLoading(false)
      onSuccessHandle()
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const deleteKeyFromNestedObject = (obj, keysToDelete) => {
    const keys = keysToDelete.split(".")

    // Traverse the nested object to find the parent object
    let parent = obj
    for (let i = 0; i < keys.length - 1; i++) {
      if (parent[keys[i]] && typeof parent[keys[i]] === "object") {
        parent = parent[keys[i]]
      } else {
        // Key not found, return original object
        return obj
      }
    }

    // Delete the key from the parent object
    delete parent[keys[keys.length - 1]]

    return obj
  }

  return (
    <QdContainer>
      {openSuccessModal(showSuccessModal, closeSuccess)}
      {openEditModal(
        showEditModal,
        toggleEditModal,
        editingData,
        cancelEdit,
        onSuccessHandle
      )}
      {openDeleteConfirmModal(
        showDeleteConfirm,
        toggleDeleteConfirm,
        loading,
        deletingData,
        remove
      )}
      <Card>
        <CardBody>
          <Col>
            <div className="float-start">
              <h5 className="text-primary">Customisation</h5>
            </div>
          </Col>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <TenantCustomisationTableContainer
            columns={columns}
            data={custdata}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={50}
            loading={loading}
            refreshTable={loadData}
            totalRows={custdata.length}
          />
        </CardBody>
      </Card>
    </QdContainer>
  )
}

export default TenantCustomisation

function openDeleteConfirmModal(
  showDeleteConfirm,
  toggleDeleteConfirm,
  loading,
  deletingData,
  remove
) {
  return (
    <Modal
      isOpen={showDeleteConfirm}
      toggle={() => {
        toggleDeleteConfirm()
      }}
      centered
      size="sm"
    >
      <LoadingOverlay active={loading} spinner>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              toggleDeleteConfirm()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={() => toggleDeleteConfirm()}>
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body">
          Are you sure you want to delete the customisation{" "}
          <span className="text-primary">{deletingData?.display_name}</span> ?
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => remove()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secodnary"
            onClick={() => toggleDeleteConfirm()}
          >
            Cancel
          </button>
        </div>
      </LoadingOverlay>
    </Modal>
  )
}

function openSuccessModal(showSuccessModal, closeSuccess) {
  return (
    <Modal isOpen={showSuccessModal} centered size="lg">
      <Success
        message="Successfully updated the tenant customisation"
        close={closeSuccess}
      />
    </Modal>
  )
}

function openEditModal(
  showEditModal,
  toggleEditModal,
  editingData,
  cancelEdit,
  onSuccessHandle
) {
  return (
    <Modal
      isOpen={showEditModal}
      toggle={() => {
        toggleEditModal()
      }}
      centered
      size="lg"
    >
      <EditTenantCustomisation
        data={editingData}
        cancel={cancelEdit}
        onSuccess={onSuccessHandle}
      />
    </Modal>
  )
}
