import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useMemo, useState } from "react"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  InputGroup,
  Label,
  Modal,
  Row,
} from "reactstrap"
import ReportDetails from "./ReportDetails"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"

import Cookies from "js-cookie"
import ClosableTag from "pages/Notification/ClosableTag"
import EmailApptReportContainer from "components/Common/EmailApptReportContainer"
import { get } from "utils/apiClient"

function EmailApptReport() {
  const [loading, setLoading] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [reports, setReports] = useState([])
  const api_url = process.env.REACT_APP_API_URL
  const [isDateRange, setIsDateRange] = useState(false)
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [filterApplied, setFilterApplied] = useState(false)

  useEffect(() => {
    reload()
  }, [])

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "tenant_id",
          Header: "Tenant",
          allowFilter: true,
        },
        {
          accessor: "st_id",
          Header: "Stop Id",
        },
        {
          accessor: "m_loc_id",
          Header: "Location",
          allowFilter: true,
        },
        {
          accessor: "request_sent_on",
          Header: "Requested On",
        },
        {
          accessor: "response_rcvd_on",
          Header: "Responded On",
        },
        {
          accessor: "confirmation_code",
          Header: "Confirmation Code",
        },
        {
          accessor: "confirmation_date",
          Header: "Confirmation Date",
        },
        {
          accessor: "mail_tracker",
          Header: "Mail Tracker",
          allowFilter: true,
        },
        {
          accessor: "final_status",
          Header: "Status",
          allowFilter: true,
        },
        {
          accessor: "",
          Header: "Action",
          Cell: ({ row }) => (
            <span
              className="pointer text-primary"
              onClick={() => handleRowClick(row.index)}
            >
              Details
            </span>
          ),
        },
      ],
      []
    )
  }

  const columns = getColumns()

  const reload = async () => {
    setLoading(true)
    try {
      const response = await get('email/reports/confirmation')
      const jsonData = response
      console.log(jsonData)
      setReports(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const search = async () => {
    setLoading(true)
    try {
      var url =  "email/reports/confirmation"

      if (fromDate && toDate) {
        url = url + `?rcvd_from_date=${fromDate}&rcvd_to_date=${toDate}`
      }

      const response = await get(url)

      const jsonData = response
      setFilterApplied(true)
      console.log(jsonData)
      setReports(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const handleRowClick = index => {
    console.log(index)
    setSelectedIndex(index)
    setShowDetails(true)
  }

  const handlePrev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    }
  }

  const handleNext = () => {
    if (selectedIndex < reports.length - 1) {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  const togShowDetails = () => {
    setShowDetails(false)
    setSelectedIndex(null)
  }

  const togDateRange = () => {
    setIsDateRange(!isDateRange)
  }

  const handleFromDate = e => {
    var d = new Date(e)
    var fromDateStr = moment(d).format("yyyy-MM-DD")
    setFromDate(fromDateStr)
  }

  const handleToDate = e => {
    var d = new Date(e)
    var toDateStr = moment(d).format("yyyy-MM-DD")
    setToDate(toDateStr)
  }

  const searchCustomDateRange = () => {
    search()
    setIsDateRange(false)
  }

  const clearFilter = async () => {
    setFilterApplied(false)
    setFromDate(pre => null)
    setToDate(pre => null)
    await reload()
  }

  const setFilteredData = data => {
    setReports(data)
  }

  return (
    <QdContainer>
      <Modal
        isOpen={isDateRange}
        toggle={() => {
          togDateRange()
        }}
        centered
        size="sm"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Date Range</h5>
          <button
            type="button"
            onClick={() => {
              togDateRange(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={() => togDateRange()}>
              &times;
            </span>
          </button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            searchCustomDateRange()
          }}
        >
          <div className="modal-body">
            <div className="form-group mb-4">
              <Label>From Date</Label>
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                  }}
                  onChange={handleFromDate}
                  value={fromDate}
                />
              </InputGroup>
            </div>
            <div className="form-group mb-4">
              <Label>To Date</Label>
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                  }}
                  onChange={handleToDate}
                  value={toDate}
                />
              </InputGroup>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Ok
            </button>
          </div>
        </Form>
      </Modal>
      {selectedIndex != null && (
        <Modal
          isOpen={showDetails}
          toggle={() => {
            togShowDetails()
          }}
          centered
          size="xl"
        >
          <ReportDetails
            onClose={togShowDetails}
            data={reports[selectedIndex]}
            onNext={handleNext}
            onPrev={handlePrev}
            isPrevDisabled={selectedIndex === 0}
            isNextDisabled={selectedIndex === reports.length - 1}
          />
        </Modal>
      )}
      <Card>
        <CardBody>
          <Col>
            <div className="float-start">
              <h5 className="text-primary">Email Appointment Report</h5>
            </div>
          </Col>
          <Col>
            <div className="float-end">
              <div className="d-flex gap-2">
                <Button
                  color="primary"
                  className="btn btn-sm"
                  onClick={togDateRange}
                >
                  Filter
                </Button>
                <span
                  onClick={() => {
                    reload()
                  }}
                  className="pointer text-primary"
                >
                  <i className="fas fa-sync-alt" />
                </span>
              </div>
            </div>
          </Col>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {filterApplied && fromDate && toDate && (
            <ClosableTag
              showClose={true}
              label={`${fromDate} - ${toDate}`}
              onClose={clearFilter}
            />
          )}

          <EmailApptReportContainer
            columns={columns}
            data={reports}
            isGlobalFilter={true}
            customPageSize={50}
            mapDisabled={false}
            loading={loading}
            refreshTable={reload}
            setFilteredData={setFilteredData}
            className="custom-header-css"
          />
        </CardBody>
      </Card>
    </QdContainer>
  )
}

export default EmailApptReport
