import ClosableTag from "pages/Notification/ClosableTag"
import Success from "pages/NotificationModal/Success"
import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "reactstrap"
import classes from "./EditAddresses.scss"
import LoadingOverlay from "react-loading-overlay"
import NameEmailModal from "./NameEmailModal"
import Cookies from "js-cookie"

const EditAddresses = props => {
  const [tos, setTos] = useState([])
  const [ccs, setCcs] = useState([])
  const [action, setAction] = useState()
  const [showModal, setShowModal] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      if (props.data?.cc?.length > 1) {
        setCcs(props.data.cc.split(","))
      }
      if (props.data?.to?.length > 1) {
        setTos(props.data.to.split(","))
      }
      setLoading(false)
    }, 2000)
  }, [])

  const cancel = () => {
    props.cancel()
  }

  const removeTag = (dataset, index, source) => {
    const newTags = [...dataset]
    newTags.splice(index, 1)
    if (source === "to") setTos(newTags)
    else if (source === "cc") setCcs(newTags)
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const cancelEdit = () => {
    props.cancelEdit()
  }

  const addEmail = values => {
    var emailData = ""
    if (values.name) {
      emailData = values.name + "<"
      emailData = emailData + values.email + ">"
    } else {
      emailData = values.email
    }

    if (action === "to") {
      setTos([...tos, emailData])
      setShowModal(false)
    } else if (action === "ccs") {
      setCcs([...ccs, emailData])
      setShowModal(false)
    }
  }
  const save = async () => {
    var commaSepTos = tos.length > 0 ? tos.join(",") : null
    var commaSepCcs = ccs.length > 0 ? ccs.join(",") : null
    var data = {
      loc_id: props.locationId,
      appt_sys_type: "EMAIL",
      to: commaSepTos,
    }

    if (commaSepCcs) {
      data["cc"] = commaSepCcs
    } else {
      data["cc"] = null
    }

    try {
      setLoading(true)
      const token = Cookies.get("idToken")

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
      const response = await fetch(
        api_url + "location-management/email-config/",
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }
      setLoading(false)
      setShowSuccess(true)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const closeEmailModel = () => {
    setShowModal(false)
  }

  const reload = () => {
    props.reload()
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          toggleModal()
        }}
        centered
        size="sm"
      >
        <NameEmailModal addEmail={addEmail} closeEmailModal={closeEmailModel} />
      </Modal>
      <Modal
        isOpen={showSuccess}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="sm"
      >
        <Success
          message={"Successfully saved the email address !!"}
          close={reload}
        />
      </Modal>
      <LoadingOverlay active={loading} spinner>
        <Row>
          <Col md={"3"}>TO</Col>
          <Col md={"9"}>
            <div className="d-flex wrapper p-0">
              {tos?.map((tag, index) => (
                <ClosableTag
                  showClose={true}
                  key={index}
                  label={tag}
                  onClose={() => removeTag(tos, index, "to")}
                />
              ))}

              <span
                className="align-item-center p-3 font-size-15 pointer"
                onClick={() => {
                  setAction("to")
                  setShowModal(true)
                }}
              >
                <i className="mdi mdi-email-plus-outline text-primary"></i>
              </span>
            </div>
          </Col>
        </Row>
        <div className="border-bottom mb-2"></div>
        <Row>
          <Col md={"3"}>CC</Col>
          <Col md={"9"}>
            <div className="d-flex wrapper p-0">
              {ccs?.map((tag, index) => (
                <ClosableTag
                  showClose={true}
                  key={index}
                  label={tag}
                  onClose={() => removeTag(ccs, index, "cc")}
                />
              ))}

              <span
                className="align-item-center p-3 font-size-15 pointer"
                onClick={() => {
                  setAction("ccs")
                  setShowModal(true)
                }}
              >
                <i className="mdi mdi-email-plus-outline text-primary"></i>
              </span>
            </div>
          </Col>
        </Row>
        <div className="border-bottom mb-4"></div>

        <div className="modal-footer">
          <Button className="btn btn-soft" color="primary" onClick={save}>
            Save
          </Button>
          <Button color="scondary" onClick={cancel}>
            Cancel
          </Button>
        </div>
      </LoadingOverlay>
    </>
  )
}

export default EditAddresses
