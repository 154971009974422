import React, { createContext, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import { get, post, put } from "utils/apiClient"


const CredContext = createContext()

function Provider({ children }) {
  const api_url = process.env.REACT_APP_API_URL

  const [creds, setCreds] = useState([])
  const [apptSysTypes, setApptSysTypes] = useState([])
  const [audits, setAudits] = useState([])
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [msg, setMsg] = useState()
  const url = api_url + "appt-sys"

  const fetchCreds = async () => {
    setLoading(true)
    try {
      const response = await get('appt-sys')

      setLoading(false)
      let result = response?.sort((a, b) =>
        a.appt_sys.localeCompare(b.appt_sys)
      )
      setCreds(result)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }


  }

  const fetchAllApptSys = async (search_term) => {
    setFormLoading(true)
    let response = null
    try {
      const url = search_term ? 'appt-sys/all?search_term=' + search_term : 'appt-sys/all'
      response = await get(url)


      setFormLoading(false)
      console.log(response)
      setApptSysTypes(response)


    } catch (error) {
      console.error(error)
      setFormLoading(false)
    }

  }

  const create = async obj => {
    setFormLoading(true)
    try {
      const response = await post('appt-sys', obj)
      setFormLoading(false)
      return { data: response.data, error: null }
    } catch (e) {
      console.log(e)
      setFormLoading(false)
      return { data: null, error: e.message }
    }
  }

  const update = async obj => {
    setFormLoading(true)
    try {
      const response = await put('appt-sys', obj)
      setFormLoading(false)
      return { data: response, error: null }
    } catch (e) {
      setFormLoading(false)
      return { data: null, error: e.message }
    }
  }

  const fetchAuditHistory = async m_key => {
    let obj = {
      m_key: m_key,
    }
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      const response = await axios.post(url + "/audits", obj, {
        withCredentials: true,
      })

      if (response.status >= 200 && response.status < 300) {
        setAudits(response.data)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const valueToShare = {
    creds,
    loading,
    fetchCreds,
    create,
    update,
    fetchAuditHistory,
    audits,
    success,
    setSuccess,
    formLoading,
    apptSysTypes,
    fetchAllApptSys,
    error,
    msg,
  }

  return (
    <CredContext.Provider value={valueToShare}>{children}</CredContext.Provider>
  )
}

export { Provider }
export default CredContext
