import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function TemplateConfig() {
    const { mLocId } = useParams("m_loc_id")
    const [loading, setLoading] = useState(false)
    const [configData, setConfigData] = useState(null)

    useEffect(() => {
        fetchConfig()
    },[])

    const fetchConfig = async() => {
        setLoading(true)

        try {
            const response = await get('location-management/opendock/' + m_loc_id)
            setConfigData(response)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }

    }
    return (
        <div>Template Mapping</div>
    )
}

export default TemplateConfig