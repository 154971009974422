import React, { useEffect, useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
} from "reactstrap"

import classes from "../OrderDashboard3/RelatedOrders.scss"
import Cookies from "js-cookie"
import StopInfoDetails from "./StopInfoDetails"

const StopRelatedOrders = ({ data }) => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState(null)
  const [modal_center, setmodal_center] = useState(false)
  const [currentOrder, setCurrentOrder] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetchData(data)
  }, [])

  function tog_center(status) {
    setmodal_center(!modal_center)
  }

  const fetchData = async row => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "stop/related-order/" + row.groupId,
        { credentials: "include" }
      ) // Replace with your API endpoint

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setOrders(jsonData)
      setLoading(false)
      console.log(jsonData)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Stop Details</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={() => setmodal_center(false)}>
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body">
          <StopInfoDetails data={currentOrder} />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setmodal_center(false)
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
      <Card outline color="primary" className="border">
        {/* <CardTitle className="font-size-12 text-primary">
        Related Orders
      </CardTitle> */}
        {loading && (
          <CardBody className="p-1 cardBody">
            <div>Loading...</div>
          </CardBody>
        )}
        {!loading && orders && (
          <CardBody className="p-1">
            <Table className="table p-0 mb-0 table-white">
              <thead>
                <tr>
                  <th>Qued #</th>
                  <th>Order #</th>
                  <th>Stop Type</th>
                  <th>Location Id</th>
                  <th>City/State</th>
                  <th>Miles</th>
                  <th>Early Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((item, index) => {
                  return (
                    <tr key={index} className="tr-active">
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        <span
                          className="pointer text-primary"
                          onClick={() => {
                            tog_center()
                            setCurrentOrder(item)
                          }}
                        >
                          {item.quedId}
                        </span>
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.orderNumber}
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.stopType}
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.tmsLocationId}
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.location.city} - {item.location.state}
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.milesFromPreviousStop}
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.tmsRequestedApptDateEarly}
                      </td>
                      <td
                        className={
                          data.quedId === item.quedId ? "tr-active" : ""
                        }
                      >
                        {item.apppointmentStatus}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  )
}

export default StopRelatedOrders
