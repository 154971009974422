import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

import person from "../../../assets/images/users/person3.png"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";


const BASE_URL = process.env.REACT_APP_API_URL

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  const navigate = useNavigate();

  const logout = async () => {
    try {
      // Sign out from AWS Cognito
      await Auth.signOut();

      // Optional: Clear additional cookies (if any)
      Cookies.remove('idToken'); // Replace 'userToken' with your cookie name
      Cookies.remove('email', { path: '/' });
      Cookies.remove('user', { path: '/' });

      Cookies.remove('accessToken');
      Cookies.remove('user', { path: '/' })
      Cookies.remove('role', { path: '/' })
      Cookies.remove('tenantId', { path: '/' })

      console.log('User signed out successfully.');
      // const res = await fetch(`${BASE_URL}set-cookie/clear`,  {
      //   method: 'POST',
      //   body: null
      // })

      // Redirect to the login page
      window.location.href = '/';
    } catch (error) {
      console.error('Error during sign out:', error);
    }

  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <div style={{'backgroundImage': `{localStorage.getItem("profilePic")}`}} className="rounded-circle header-profile-user">

          </div> */}
          <img
            className="header-profile-user"
            src={person}
            alt="Header Avatar"
          />

          <span className="d-none d-xl-inline-block ms-2 me-1">{Cookies.get('user')}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem className="dropdown-item" onClick={logout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
