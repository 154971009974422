import React, { useEffect, useState } from "react"
import EditForm from "./EditForm"
import classnames from "classnames"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Modal,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
} from "reactstrap"
import Preview from "./Preview"
import { useNavigate } from "react-router-dom"
import Success from "pages/NotificationModal/Success"
import ValidationData from "./ValidationData"
import VarData from "./VarData"
import EmailRule from "./EmailRule"
import Cookies from "js-cookie"
import { get, post } from "utils/apiClient"

const StaggedData = props => {
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [data, setData] = useState(props.data)
  const [popovertop, setpopovertop] = useState(false)
  const [hasPublished, setHasPublished] = useState(false)
  const [mLocId, setMLocId] = useState(props.m_loc_id)
  const [showPubSuccessModal, setShowPubSuccessModal] = useState(false)

  const [showPopover, setShowPopover] = useState(Array(10).fill(false)) // Assume 10 items for example

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const isEmpty = obj => {
    return Object.keys(obj).length === 0
  }

  useEffect(() => {
    if (!data.has_location_cfg && !data.has_tenant_cfg && isEmpty(data)) {
      edit()
    }
  }, [])

  const edit = () => {
    setIsEditing(true)
  }

  const cancelEdit = () => {
    setIsEditing(false)
    if (!data.has_location_cfg && !data.has_tenant_cfg && isEmpty(data)) {
      navigate("/location-management")
    } else {
      getEmailTemplate()
    }
  }

  const getEmailTemplate = async () => {
    try {
      setLoading(true)
      const url = "email/templatemapping/" + props.m_loc_id
      const response = await get(url)
      
      if (response) {
        if (response.staged) {
          setData(response.staged)
        }

        if (response.published) {
          setHasPublished(true)
        }
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const goToLocationManagement = () => {
    navigate("/location-management")
  }

  const publish = async () => {
    var reqObj = {
      htmlTemplate: data.htmlTemplate,
      vars: data.vars,
      confirmation_msgs: data.confirmation_msgs,
      sig_identifier_regex: data.sig_identifier_regex,
      publish: true,
    }
    try {
      setLoading(true)
      const url = "email/templatemapping/" + props.m_loc_id + "/publish"

      const response = await post(url, reqObj)

      
      if (response) {
        console.log(response)
        if (response.content?.body) {
          setBodyHtml(atob(response.content.body))
        }
        if (response.content?.subject) {
          setSubHtml(atob(response.content.subject))
        }
      }
      setLoading(false)
      setShowPubSuccessModal(true)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const togPubSuccessModal = () => {
    setShowPubSuccessModal(!showPubSuccessModal)
  }

  return (
    <Card>
      <CardBody>
        <Modal
          isOpen={showPubSuccessModal}
          toggle={() => {
            togPubSuccessModal()
          }}
          centered
          size="sm"
        >
          <Success
            message={"Location mapping published successfully !!"}
            close={goToLocationManagement}
          />
        </Modal>
        {loading && <div>Loading...</div>}
        {isEditing && (
          <EditForm
            data={data}
            cancel={cancelEdit}
            qdLocationId={props.locationId}
            isPublished={false}
            m_loc_id={mLocId}
          />
        )}
        {!loading && data && !isEditing && (
          <Card className="p-3">
            <Row>
              <Col>
                <span className="text-primary text-size-16 m-2">
                  Email Template Configuration
                </span>
                <Badge color="secondary" className="me-1">
                  Draft
                </Badge>
              </Col>
              <Col className="d-flex justify-content-end gap-2">
                {props.hasPublished && (
                  <Button
                    type="button"
                    className="btn btn-soft-primary btn-sm waves-effect waves-light"
                    onClick={props.showPublished}
                  >
                    Show Published
                  </Button>
                )}
                <Button
                  type="button"
                  className="btn btn-soft-primary btn-sm waves-effect waves-light"
                  onClick={edit}
                >
                  Edit
                </Button>
              </Col>
            </Row>
            <CardSubtitle>
              <span className="m-2 text-muted">{props.locationName}</span>
            </CardSubtitle>
            <CardBody>
              <Row>
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody className="p-2 cardBody">
                    <Row className="mb-2 font-weight-100">
                      <Col md="2" className={"text-muted"}>
                        <span>Email Tempalte:</span>
                      </Col>
                      <Col md="10">
                        <span className="text-primary">
                          {data.htmlTemplate}
                        </span>
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>

                    <Row>
                      <VarData vars={data.vars} />
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row>
                      <Col md="2" className={"text-muted"}>
                        <span>Confirmation Message:</span>
                      </Col>
                      <Col md="10">
                        {data.confirmation_msgs?.map((item, index) => (
                          <Row key={index}>
                            <p>{item}</p>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row>
                      <Col md="2" className={"text-muted"}>
                        <span>Signature:</span>
                      </Col>
                      <Col md="10">
                        {data.sig_identifier_regex?.map((item, index) => (
                          <Row key={index}>
                            <p>{item}</p>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                    <div className="border-bottom mb-2"></div>
                    <Row>
                      <ValidationData
                        validations={data.validations}
                        isEdit={false}
                      />
                    </Row>

                    <div className="border-bottom mb-2"></div>
                    <Row>
                      <Col md="2" className={"text-muted"}>
                        <span>From:</span>
                      </Col>
                      <Col md="10">
                        <span className="text-primary">{data.from}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row>
                <EmailRule
                  data={data}
                  isEdit={isEditing}
                  mLocId={mLocId}
                  reload={getEmailTemplate}
                />
              </Row>

              <Preview
                type="staged"
                locationId={props.locationId}
                m_loc_id={mLocId}
                publish={publish}
                cfgType="staged"
              />

              <div className="modal-footer">
                <Button
                  type="secondary"
                  className="btn btn-secondary"
                  onClick={goToLocationManagement}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </CardBody>
    </Card>
  )
}

export default StaggedData
