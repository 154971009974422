import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import ClientCredGrantDetails from "./ClientCredGrantDetails"
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
} from "reactstrap"
import Success from "pages/NotificationModal/Success"
import { useFormik } from "formik"
import * as Yup from "yup"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

const ClientCredGrantFlow = () => {
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [data, setData] = useState(null)
  const [showEdit, setShowEdit] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const api_url = process.env.REACT_APP_API_URL
  const [tenant, setTenant] = useState()

  const navigate = useNavigate()

  const openEditForm = () => {
    setShowEdit(true)
  }

  useEffect(() => {
    getData()
    loadTenantData()
  }, [])

  const togEdit = () => {
    setShowEdit(!showEdit)
  }

  const loadTenantData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url

      url = url + "tenant"

      const response = await fetch(url, { credentials: "include" })

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setTenant(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const create = async values => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url
      var provider_name = tenant.shortname.replace(/_/g, "-") + "-CLIENTCRED"

      url = url + "idp/client-cred"
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_name: values.clientName,
          provider_name: provider_name,
        }),
        credentials: "include",
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setError(res.error)
        setLoading(false)
        throw new Error(res.error)
      }
      setLoading(false)
      const res = await response.json()
      setData(res)
      setShowEdit(false)
      setShowSuccess(true)
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "idp/client-cred", {
        credentials: "include",
      })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      if (jsonData) {
        setIsNew(false)
      }
      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const closeSuccess = () => {
    setShowSuccess(false)
    getData()
  }

  const goBack = () => {
    navigate("/auth-config")
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      clientName: "",
    },
    validationSchema: Yup.object({
      clientName: Yup.string().required("Please Enter client name"),
    }),

    onSubmit: values => {
      create(values)
    },
  })

  return (
    <QdContainer>
      <Modal
        isOpen={showEdit}
        toggle={() => {
          togEdit()
        }}
        centered
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Create New Client Credentials</h5>
          <button
            type="button"
            onClick={() => {
              togEdit()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              onClick={() => {
                togEdit()
              }}
            >
              &times;
            </span>
          </button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <LoadingOverlay active={loading} spinner>
            <div className="modal-body">
              <div className="form-group mb-4">
                <Label>Client Name</Label>
                <Input
                  name="clientName"
                  className="form-control"
                  placeholder="Enter client name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.clientName || ""}
                  invalid={
                    validation.touched.clientName &&
                    validation.errors.clientName
                      ? true
                      : false
                  }
                />
                {validation.touched.clientName &&
                validation.errors.clientName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.clientName}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary btn-block btn-sm"
                type="submit"
              >
                Save
              </button>
              <button
                className="btn btn-secondary btn-block btn-sm"
                type="button"
                onClick={togEdit}
              >
                Cancel
              </button>
            </div>
          </LoadingOverlay>
        </Form>
      </Modal>
      <Modal
        isOpen={showSuccess}
        toggle={() => {
          closeSuccess(null)
        }}
        centered
        size="sm"
      >
        <Success
          message={"Successfully created client credential app client !!"}
          close={closeSuccess}
        />
      </Modal>
      {loading && <div>Loading....</div>}
      {!isNew && !loading && data && (
        <ClientCredGrantDetails data={data} goBack={goBack} />
      )}
      {isNew && !loading && !data && (
        <div>
          <Card>
            <CardBody className="p-3">
              <div className="text-center mt-3">
                <span className="mt-3">No configuration found</span>
                <div className="mt-4">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={openEditForm}
                  >
                    Create New
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </QdContainer>
  )
}

export default ClientCredGrantFlow
