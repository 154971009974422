import React, { useState } from 'react'
import VarData from './VarData'
import { Modal, Row } from 'reactstrap'
import { post } from 'utils/apiClient'
import LoadingOverlay from 'react-loading-overlay'
import Success from 'pages/NotificationModal/Success'
import Preview from './Preview'

function BaseConfig({ configData, cancel, m_loc_id, fetchConfig }) {

    const [varMaps, setVarMaps] = useState(configData.var_map)
    const [loading, setLoading] = useState(false)
    const [isPreviewed, setIsPreviewed] = useState(false)

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    

    const accept = async () => {
        setLoading(true)
        try {

            var obj = {
                "var_map": varMaps,
                "m_loc_id": m_loc_id,
                'config': configData.config
            }
            const response = await post('location-management/opendock/base', obj)
            setLoading(false)
            setShowSuccessModal(true)

        } catch (error) {
            console.error(error)
            setLoading(false)
        }

    }

    const togSuccessModal = () => {
        setShowSuccessModal(!showSuccessModal)
    }

    const closeSuccess = () => {
        setShowSuccessModal(false)
        cancel()
    }

    const publish = () => {

    }


    return (
        <>
        <Modal isOpen={showSuccessModal} centered size="sm">
        <Success
          message={"Successfully created the base configuration, Please proceed with the location mapping."}
          close={closeSuccess}
        />
      </Modal>
        <LoadingOverlay active={loading} spinner>
            <VarData vars={varMaps} labelWidth={2} setVars={setVarMaps} isBase={true}/>
            <Preview  type="staged" configData={configData} varMaps={varMaps} setIsPreviewed={setIsPreviewed}
                is_tenant_specs={false} 
                m_loc_id={m_loc_id}
                publish={publish}
                cfgType="staged"/>
            <div className="modal-footer">
                <button
                    id="addValButton"
                    type="button"
                    className="btn btn-primary m-3"
                    onClick={accept}
                    disabled={!isPreviewed}
                >
                    Accept
                </button>
                {" "}
                <button
                    type="button"
                    className="btn btn-secondary me-3"
                    onClick={cancel}
                >
                    Cancel
                </button>
            </div>
            </LoadingOverlay>
        </>

    )
}

export default BaseConfig