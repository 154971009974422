import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  openEditModal,
  openSuccessModal,
  openDeleteConfigModal,
  openAddMappingModal,
  configurationDetails,
  configurationValidationDetails,
  openValidationDeletionModal,
} from "./openEditModal"
import Cookies from "js-cookie"

const ViewFinalConfig = props => {
  const [varMaps, setVarMaps] = useState([])
  const [rootMaps, setRootMaps] = useState([])
  const [customFieldMaps, setCustomFieldMaps] = useState([])
  const [dockConfigs, setDockConfigs] = useState([])
  const [validations, setValidations] = useState([])
  const [isNew, setIsNew] = useState(false)

  const [showEditModal, setShowEditModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showAddMappingModal, setShowAddMappingModal] = useState(false)
  const [showValidationEditModal, setShowValidationEditModal] = useState(false)
  const [showValidationCreateModal, setShowValidationCreateModal] =
    useState(false)
  const [showValidationDeleteModal, setShowValidationDeleteModal] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [editingData, setEditingData] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    if (!props.data) {
      setIsNew(true)
    } else {
      setIsNew(false)
    }
    prepareData()
  }, [props.data])

  function tog_center(status) {
    setShowEditModal(!showEditModal)
    setShowDeleteConfirm(!showDeleteConfirm)
  }

  const save = async values => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var newData = props.data
      var type = editingData.type
      var obj = { name: values.name, value: values.value }

      enrichPayload()
      console.log(newData)
      await executeRestCall(token)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }

    async function executeRestCall(token) {
      const config = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
        credentials: "include",
      }

      const response = await fetch(
        api_url + "locations/config/final/" + props.locationId,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      props.refresh()
      setLoading(false)
      setShowEditModal(false)
      setShowSuccessModal(true)
    }

    function enrichPayload() {
      if (type == "var_map") {
        varMaps[editingData.index] = obj
        const jsonObject = varMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["var_map"] = jsonObject
      } else if (type == "root_mapping") {
        rootMaps[editingData.index] = obj
        const jsonObject = rootMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["root_mapping"] = jsonObject
      } else if (type == "custom_field_mapping") {
        customFieldMaps[editingData.index] = obj
        const jsonObject = customFieldMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["custom_field_mapping"] = jsonObject
      }
    }
  }

  const saveValidation = async values => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var newData = props.data
      var type = editingData.type
      var obj = {
        key: values.key,
        var: values.var,
        valdn_type: values.valdn_type,
        value: values.value,
        message: values.message,
      }

      enrichPayload()
      console.log(newData)
      await executeRestCall(token)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }

    async function executeRestCall(token) {
      const config = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
        credentials: "include",
      }

      const response = await fetch(
        api_url + "locations/config/final/" + props.locationId,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      props.refresh()
      setLoading(false)
      setShowEditModal(false)
      setShowSuccessModal(true)
    }

    function enrichPayload() {
      validations[editingData.index] = obj
      var resultValidations = {}
      validations.forEach(obj => {
        const { key, ...details } = obj
        resultValidations[key] = details
      })

      newData["validations"] = resultValidations
    }
  }

  const deleteConfig = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var newData = props.data
      var type = editingData.type
      enrichPayload()
      console.log(newData)
      await executeRestCall(token)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }

    async function executeRestCall(token) {
      const config = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
        credentials: "include",
      }

      const response = await fetch(
        api_url + "locations/config/base/" + props.locationId,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      props.refresh()
      setLoading(false)
      setShowDeleteConfirm(false)
      setShowSuccessModal(true)
    }

    function enrichPayload() {
      if (type == "var_map") {
        varMaps.splice(editingData.index, 1)
        const jsonObject = varMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["var_map"] = jsonObject
      } else if (type == "root_mapping") {
        rootMaps.splice(editingData.index, 1)
        const jsonObject = rootMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["root_mapping"] = jsonObject
      } else if (type == "custom_field_mapping") {
        customFieldMaps.splice(editingData.index, 1)
        const jsonObject = customFieldMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["custom_field_mapping"] = jsonObject
      } else if (type == "loadType") {
        dockConfigs.splice(editingData.index, 1)
        const jsonObject = dockConfigs.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["dock_config"]["loadType"] = jsonObject
      }
    }
  }

  const deleteValidation = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var newData = props.data
      var type = editingData.type
      enrichPayload()
      console.log(newData)
      await executeRestCall(token)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }

    async function executeRestCall(token) {
      const config = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
        credentials: "include",
      }

      const response = await fetch(
        api_url + "locations/config/base/" + props.locationId,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      props.refresh()
      setLoading(false)
      setShowValidationDeleteModal(false)
      setShowSuccessModal(true)
    }

    function enrichPayload() {
      validations.splice(editingData.index, 1)
      var resultValidations = {}
      validations.forEach(obj => {
        const { key, ...details } = obj
        resultValidations[key] = details
      })

      newData["validations"] = resultValidations
    }
  }

  const cancel = () => {
    setShowEditModal(false)
    setShowSuccessModal(false)
    setShowAddMappingModal(false)
    setShowValidationEditModal(false)
    setEditingData(null)
    setShowValidationCreateModal(false)
    props.refresh()
  }

  const prepareData = () => {
    if (props.data) {
      console.log(props.data)
      if (props.data.var_map) {
        var varMapsData = []
        for (const key in props.data.var_map) {
          varMapsData.push({
            name: `${key}`,
            value: `${props.data.var_map[key]}`,
          })
        }
        setVarMaps(varMapsData)
      }

      if (props.data.root_mapping) {
        var rootMapData = []
        for (const key in props.data.root_mapping) {
          rootMapData.push({
            name: `${key}`,
            value: `${props.data.root_mapping[key]}`,
          })
        }
        setRootMaps(rootMapData)
      }

      if (props.data.custom_field_mapping) {
        var customFieldMapData = []
        for (const key in props.data.custom_field_mapping) {
          customFieldMapData.push({
            name: `${key}`,
            value: `${props.data.custom_field_mapping[key]}`,
          })
        }
        setCustomFieldMaps(customFieldMapData)
      }

      if (props.data.dock_config) {
        var dockConfigData = []
        for (const key in props.data.dock_config.loadType) {
          dockConfigData.push({
            name: `${key}`,
            value: `${props.data.dock_config.loadType[key]}`,
          })
        }
        setDockConfigs(dockConfigData)
      }
      if (props.data.validations) {
        var validationData = []
        for (const key in props.data.validations) {
          var obj = props.data.validations[key]
          console.log(obj)
          console.log(obj.var)
          validationData.push({
            key: `${key}`,
            var: obj.var,
            message: obj.message,
            value: obj.value,
            valdn_type: obj.valdn_type,
          })
        }
        setValidations(validationData)
      }
    }

    console.log(varMaps)
  }

  const displayEditModal = (idx, type, data) => {
    setEditingData({ index: idx, type: type, data: data })
    setShowEditModal(true)
  }

  const displayValidationEditModal = (idx, data) => {
    setEditingData({ index: idx, type: "validations", data: data })
    setShowValidationEditModal(true)
  }

  const remove = (idx, type, data) => {
    setEditingData({ index: idx, type: type, data: data })
    setShowDeleteConfirm(true)
  }

  const removeValidation = (idx, data) => {
    setEditingData({ index: idx, type: "validations", data: data })
    setShowValidationDeleteModal(true)
  }

  const toggleAddMappingModal = () => {
    setShowAddMappingModal(!showAddMappingModal)
  }

  const toggleValidationEditModal = () => {
    setShowValidationEditModal(!showValidationEditModal)
  }

  const toggleValidationCreateModal = () => {
    setShowValidationCreateModal(!showValidationCreateModal)
  }

  const toggleValidationDeleteModal = () => {
    setShowValidationDeleteModal(!showValidationDeleteModal)
  }

  const create = async values => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var newData = null
      if (isNew) {
        newData = {}
      } else {
        newData = props.data
      }
      var type = values.type
      var obj = { name: values.name, value: values.value }
      enrichPayload()
      console.log(newData)
      await executeRestCall(token)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }

    async function executeRestCall(token) {
      const config = {
        method: isNew ? "POST" : "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
        credentials: "include",
      }

      var url = api_url + "locations/config/base/" + props.locationId

      const response = await fetch(url, config)

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      props.refresh()
      setLoading(false)
      setShowEditModal(false)
      setShowSuccessModal(true)
      setEditingData(null)
    }

    function enrichPayload() {
      if (type == "var_map") {
        varMaps.push(obj)
        const jsonObject = varMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["var_map"] = jsonObject
      } else if (type == "root_mapping") {
        rootMaps.push(obj)
        const jsonObject = rootMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["root_mapping"] = jsonObject
      } else if (type == "custom_field_mapping") {
        customFieldMaps.push(obj)
        const jsonObject = customFieldMaps.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["custom_field_mapping"] = jsonObject
      } else if (type == "loadType") {
        dockConfigs.push(obj)
        const jsonObject = dockConfigs.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {})
        newData["dock_config"]["loadType"] = jsonObject
      }
    }
  }

  const createValidation = async values => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var newData = null
      if (isNew) {
        newData = {}
      } else {
        newData = props.data
      }
      var type = values.type
      var obj = {
        key: values.key,
        var: values.var,
        valdn_type: values.valdn_type,
        value: values.value,
        message: values.message,
      }

      enrichPayload()
      console.log(newData)
      await executeRestCall(token)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }

    async function executeRestCall(token) {
      const config = {
        method: isNew ? "POST" : "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
        credentials: "include",
      }

      var url = api_url + "locations/config/base/" + props.locationId

      const response = await fetch(url, config)

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      props.refresh()
      setLoading(false)
      setShowEditModal(false)
      setShowSuccessModal(true)
      setEditingData(null)
    }

    function enrichPayload() {
      validations.push(obj)
      var resultValidations = {}
      validations.forEach(obj => {
        const { key, ...details } = obj
        resultValidations[key] = details
      })

      newData["validations"] = resultValidations
    }
  }

  return (
    <>
      {openEditModal(showEditModal, tog_center, save, editingData, cancel)}
      {openSuccessModal(tog_center, cancel, showSuccessModal)}
      {openDeleteConfigModal(
        showDeleteConfirm,
        tog_center,
        loading,
        setShowDeleteConfirm,
        editingData,
        deleteConfig
      )}
      {openAddMappingModal(
        showAddMappingModal,
        toggleAddMappingModal,
        cancel,
        create
      )}
      {openValidationEditModal(
        showValidationEditModal,
        toggleValidationEditModal,
        cancel,
        editingData,
        saveValidation
      )}
      {openValidationCreateModal(
        showValidationCreateModal,
        toggleValidationCreateModal,
        cancel,
        createValidation
      )}
      {openValidationDeletionModal(
        showValidationDeleteModal,
        toggleValidationDeleteModal,
        loading,
        setShowValidationDeleteModal,
        editingData,
        deleteValidation
      )}

      {props.allowEdit && (
        <Row>
          <Col>
            <div className="d-flex float-end mb-2">
              <UncontrolledDropdown>
                <DropdownToggle color="primary" type="button">
                  Add New<i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={toggleAddMappingModal}>
                    Mapping
                  </DropdownItem>
                  <DropdownItem onClick={toggleValidationCreateModal}>
                    Validation
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
      )}

      {configurationDetails(
        displayEditModal,
        varMaps,
        "Variable Mapping",
        "var_map",
        loading,
        remove,
        props.allowEdit
      )}
      {configurationDetails(
        displayEditModal,
        rootMaps,
        "Root Mapping",
        "root_mapping",
        loading,
        remove,
        props.allowEdit
      )}
      {configurationDetails(
        displayEditModal,
        customFieldMaps,
        "Custom Field Mapping",
        "custom_field_mapping",
        loading,
        remove,
        props.allowEdit
      )}
      {configurationDetails(
        displayEditModal,
        dockConfigs,
        "Dock Config",
        "loadType",
        loading,
        remove,
        props.allowEdit
      )}
      {configurationValidationDetails(
        loading,
        validations,
        displayValidationEditModal,
        removeValidation,
        props.allowEdit
      )}
    </>
  )
}

export default ViewFinalConfig

function openValidationEditModal(
  showEditModal,
  toggleValidationEditModal,
  cancel,
  editingData,
  saveValidation
) {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      var: editingData?.data[editingData.index]?.var,
      value: editingData?.data[editingData.index]?.value,
      valdn_type: editingData?.data[editingData.index]?.valdn_type,
      message: editingData?.data[editingData.index]?.message,
      key: editingData?.data[editingData.index]?.key,
    },
    validationSchema: Yup.object({
      var: Yup.string().required("Please enter the variable"),
      valdn_type: Yup.string().required("Please enter the validation type"),
    }),

    onSubmit: values => {
      saveValidation(values)
    },
  })

  return (
    <Modal
      isOpen={showEditModal}
      toggle={() => {
        toggleValidationEditModal()
      }}
      centered
      size="md"
    >
      <React.Fragment>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Validation</h5>
        </div>

        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Order</Label>
              <Input
                name="key"
                className="form-control"
                placeholder="Enter the order"
                type="number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.key || ""}
                invalid={
                  validation.touched.key && validation.errors.key ? true : false
                }
              />
              {validation.touched.key && validation.errors.key ? (
                <FormFeedback type="invalid">
                  {validation.errors.key}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Variable</Label>
              <Input
                name="var"
                className="form-control"
                placeholder="Enter the variable name"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.var || ""}
                invalid={
                  validation.touched.var && validation.errors.var ? true : false
                }
              />
              {validation.touched.var && validation.errors.var ? (
                <FormFeedback type="invalid">
                  {validation.errors.var}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Value</Label>
              <Input
                name="value"
                className="form-control"
                placeholder="Enter the value"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value || ""}
                invalid={
                  validation.touched.value && validation.errors.value
                    ? true
                    : false
                }
              />
              {validation.touched.value && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Validation Type</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="valdn_type"
                  value={validation.values.valdn_type || ""}
                  type="text"
                  placeholder="Enter Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.valdn_type &&
                    validation.errors.valdn_type
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.valdn_type && validation.errors.valdn_type ? (
                <FormFeedback type="invalid">
                  {validation.errors.valdn_type}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Message</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="message"
                  value={validation.values.message || ""}
                  type="text"
                  placeholder="Enter message"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.message && validation.errors.message
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.message && validation.errors.message ? (
                <FormFeedback type="invalid">
                  {validation.errors.message}
                </FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </React.Fragment>
    </Modal>
  )
}

function openValidationCreateModal(
  showValidationCreateModal,
  toggleValidationCreateModal,
  cancel,
  createValidation
) {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      var: "",
      value: "",
      valdn_type: "",
      message: "",
      key: "",
    },
    validationSchema: Yup.object({
      var: Yup.string().required("Please enter the variable"),
      valdn_type: Yup.string().required("Please enter the validation type"),
    }),

    onSubmit: values => {
      createValidation(values)
    },
  })

  return (
    <Modal
      isOpen={showValidationCreateModal}
      toggle={() => {
        toggleValidationCreateModal()
      }}
      centered
      size="md"
    >
      <React.Fragment>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Create Validation</h5>
        </div>

        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Order</Label>
              <Input
                name="key"
                className="form-control"
                placeholder="Enter the order"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.key || ""}
                invalid={
                  validation.touched.key && validation.errors.key ? true : false
                }
              />
              {validation.touched.key && validation.errors.key ? (
                <FormFeedback type="invalid">
                  {validation.errors.key}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Variable</Label>
              <Input
                name="var"
                className="form-control"
                placeholder="Enter the variable name"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.var || ""}
                invalid={
                  validation.touched.var && validation.errors.var ? true : false
                }
              />
              {validation.touched.var && validation.errors.var ? (
                <FormFeedback type="invalid">
                  {validation.errors.var}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Value</Label>
              <Input
                name="value"
                className="form-control"
                placeholder="Enter the value"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value || ""}
                invalid={
                  validation.touched.value && validation.errors.value
                    ? true
                    : false
                }
              />
              {validation.touched.value && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Validation Type</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="valdn_type"
                  value={validation.values.valdn_type || ""}
                  type="text"
                  placeholder="Enter validation type"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.valdn_type &&
                    validation.errors.valdn_type
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.valdn_type && validation.errors.valdn_type ? (
                <FormFeedback type="invalid">
                  {validation.errors.valdn_type}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Message</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="message"
                  value={validation.values.message || ""}
                  type="text"
                  placeholder="Enter message"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.message && validation.errors.message
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.message && validation.errors.message ? (
                <FormFeedback type="invalid">
                  {validation.errors.message}
                </FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </React.Fragment>
    </Modal>
  )
}
