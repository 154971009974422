import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Modal, Row } from "reactstrap"
import EditForm from "./EditForm"
import Success from "pages/NotificationModal/Success"
import Cookies from "js-cookie"

const FormCard = props => {
  const api_url = process.env.REACT_APP_API_URL

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [showEditForm, setShowEditForm] = useState(false)
  const [editingConfig, setEditingConfig] = useState()
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  useEffect(() => {
    loadConfigs()
  }, [])

  const loadConfigs = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = {}
      const response = await fetch(api_url + props.card.url, {
        credentials: "include",
      })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      setData(jsonData)

      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const togEditForm = () => {
    setShowEditForm(!showEditForm)
  }

  const save = () => {
    setShowEditForm(false)
    setShowSuccessModal(true)
  }

  const edit = item => {
    setEditingConfig(item)
    setShowEditForm(true)
  }

  const cancelEdit = () => {
    setEditingConfig(null)
    setShowEditForm(false)
  }

  const togSuccess = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  const close = () => {
    setShowSuccessModal(false)
    loadConfigs()
  }

  return (
    <React.Fragment>
      <Modal isOpen={showEditForm} toggle={togEditForm}>
        <EditForm
          closeModal={togEditForm}
          onSave={save}
          url={props.card.url}
          configData={editingConfig}
          cancelEdit={cancelEdit}
        />
      </Modal>

      <Modal isOpen={showSuccessModal} toggle={togSuccess}>
        <Success message="Configuration updated successfully!" close={close} />
      </Modal>

      <h6>
        {props.section}
        {" > "}
        {props.card.form_title}
      </h6>

      <Card>
        <CardBody>
          {loading && <div>Loading...</div>}
          {!loading && data && (
            <div className="p-2">
              {data?.map((item, index) => (
                <Row key={index} className="mb-2 border-bottom">
                  <Col md={5}>
                    <span style={{ font: "-webkit-control" }}>
                      <b>{item.display_name}</b>
                    </span>
                  </Col>
                  <Col md={6}>
                    <span className="text-muted">{item.value + ""}</span>
                  </Col>

                  <Col md={1}>
                    {!item.read_only && (
                      <i
                        className="mdi mdi-pencil font-size-16 text-primary me-1 pointer"
                        onClick={() => edit(item)}
                      />
                    )}{" "}
                  </Col>
                </Row>
              ))}
              <div className="modal-footer mt-2">
                <Button
                  color="secondary"
                  className="btn btn-secondary btn-sm"
                  onClick={props.cancel}
                >
                  cancel
                </Button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default FormCard
