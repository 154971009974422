import QuedTableContainer from "components/Common/QuedTableContainer"
import LocationTableContainer from "components/Common/LocationTableContainer"
import React, { useState, useEffect, useMemo } from "react"
import { Card, CardBody, Col, Container, Modal, Row } from "reactstrap"
import { Box } from "@mui/material"
import Cookies from "js-cookie"
import { get } from "utils/apiClient"

const LocationManagement = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [editingRow, setEditingRow] = useState(null)
  const [showText, setShowText] = useState(false)

  document.title = "Location Management | Qued Portal"

  const editRow = row => {
    console.log(row)
    setEditingRow(row?.id)
  }

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "tLocationName",
          Header: "Location Name",
        },
        {
          accessor: "appt_sys_ref",
          Header: "Appointment System",
        },
        {
          accessor: "locationId",
          Header: "Location Id",
        },
      ],
      []
    )
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await get("locations")
      setData(response)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const columns = getColumns()

  const handleAddMapping = () => {
    console.log("testing new button click")
  }

  const refreshTable = () => {
    fetchData()
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <LocationTableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={50}
                    handleAddMapping={handleAddMapping}
                    editRow={editRow}
                    editingRow={editingRow}
                    loading={loading}
                    refreshTable={refreshTable}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LocationManagement
